import { createContext, useContext } from 'react';
import { FormInstance } from 'antd';

export const FormContext = createContext<FormInstance<any> | undefined>(undefined);

const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormContext.Provider');
  } else return context;
};

export default useFormContext;
