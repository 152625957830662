import { FormField } from '../../../types/FormFields';
import { filterByKeyPrefix, getKeyPrefix } from './formTexts';
import { capitalize } from '../../../lib/formLabels';

interface shouldAddDividerResult {
  dividerAbove: boolean;
  dividerBelow: boolean;
  dividerLabel: string | undefined;
  includedPrefixes: string[];
}

function shouldAddDivider(
  itemsMapping: FormField[],
  item: FormField,
  includedPrefixes: string[]
): shouldAddDividerResult {
  function response(
    above: boolean,
    below: boolean,
    label: string | undefined,
    includedPrefixes: string[]
  ): shouldAddDividerResult {
    return {
      dividerAbove: above,
      dividerBelow: below,
      dividerLabel: label,
      includedPrefixes
    };
  }

  const includedPrefixesResult = [...includedPrefixes];
  const index = itemsMapping.findIndex((i) => i.key === item.key);
  const keyPrefix = getKeyPrefix(item.key);
  if (!keyPrefix) {
    return response(false, false, undefined, includedPrefixesResult);
  }

  // generally we add a divider if there are 2 or more items with the same key prefix
  const samePrefixItems = filterByKeyPrefix(itemsMapping, keyPrefix);
  if (keyPrefix && samePrefixItems.length > 1 && !includedPrefixesResult.includes(keyPrefix)) {
    includedPrefixesResult.push(keyPrefix);
    // makes no sense adding divider before the 1st item
    if (index > 0) {
      return response(true, false, capitalize(keyPrefix), includedPrefixesResult);
    }
  }

  // we may also need to add an empty divider after the last item with curr prefix
  const nextItem = itemsMapping[index + 1];
  if (includedPrefixesResult.includes(keyPrefix) && nextItem) {
    const isLastItemOfPrefix = getKeyPrefix(nextItem.key) !== keyPrefix;
    // but only if the next item has no divider above
    const { dividerAbove } = shouldAddDivider(itemsMapping, nextItem, includedPrefixesResult);
    if (nextItem && isLastItemOfPrefix && !dividerAbove) {
      return response(false, true, undefined, includedPrefixesResult);
    }
  }

  return response(false, false, undefined, includedPrefixesResult);
}

export default shouldAddDivider;
