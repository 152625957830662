import React from 'react';
import { Flex, Layout, theme, Typography } from 'antd';

import { authProps, BrandingProps } from '../types/afConfig';
import LoginForm from '../components/LoginForm';
import styles from './LoginPage.module.scss';
import defaultBranding from '../lib/defaultBranding';
import PageHeader from '../components/PageHeader';

interface LoginProps {
  auth?: authProps;
  branding?: BrandingProps;
}

const LoginPage = ({ auth, branding }: LoginProps) => {
  // let's make local login true by default for local development
  const localDev = process.env.NODE_ENV === 'development';
  const localEnabled = auth ? auth.strategies.includes('local') : localDev;
  const ldapEnabled = auth ? auth.strategies.includes('ldap') : false;
  const samlEntryPoint = auth?.strategies.includes('saml') ? auth.saml_redirect : undefined;
  const oidcEnabled = auth ? auth.strategies.includes('oidc') : false;
  const loginFormProps = {
    local_enabled: localEnabled,
    ldap_enabled: ldapEnabled,
    saml_url: samlEntryPoint,
    oidc_enabled: oidcEnabled
  };
  const { token } = theme.useToken();

  return (
    <>
      <PageHeader pageTitle={'Login'} branding={branding} />
      <Layout className={styles.main}>
        <Layout.Header>
          <LoginHeader branding={branding} />
        </Layout.Header>
        <Layout.Content>
          <Flex className={styles.formContainer} justify="center" vertical align="center" gap={32}>
            <Typography.Title level={2}>
              {branding?.subtitle || defaultBranding.subtitle}
            </Typography.Title>
            <LoginForm {...loginFormProps} className={styles.form} />
          </Flex>
        </Layout.Content>

        <Layout.Footer style={{ background: token.colorWhite }}>
          <div className={styles.footer}>{branding?.footer || defaultBranding.footer}</div>
        </Layout.Footer>
      </Layout>
    </>
  );
};

function LoginHeader({ branding }: { branding?: BrandingProps }) {
  const { token } = theme.useToken();
  return (
    <Flex align="center" gap={24} style={{ height: '100%' }}>
      <div
        data-testid="custom-head-snippet"
        dangerouslySetInnerHTML={{
          __html: branding?.custom_head_snippet || ''
        }}
      />

      <Flex align="center" gap={24}>
        <img
          src={branding?.logo_url || defaultBranding.logo_url}
          alt={'Logo'}
          className={styles.logo}
        />
        <Typography.Title
          level={2}
          className={styles.title}
          style={{ color: token.colorWhite, margin: 0 }}
        >
          {branding?.title || defaultBranding.title}
        </Typography.Title>
      </Flex>
    </Flex>
  );
}

export default LoginPage;
