import React from 'react';
import { FormInstance } from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import { HookAPI } from 'antd/lib/modal/useModal';

import { validateConfigResp } from '../../../api/validateAndSaveConfig';
import { configSourceData } from '../../../api/getConfigHandler';
import { showZodErrors } from '../../formElements/utilities/showError';
import showConfigFormMessage from './showConfigFormMessage';
import afLogoutRedirect from './afLogoutRedirect';
import { LOGOUT_ROUTE } from 'shared/routes';

async function showSaveConfigResult(
  resp: validateConfigResp,
  configSourceObj: configSourceData | undefined,
  form: FormInstance,
  message: MessageInstance,
  modal: HookAPI
): Promise<void> {
  const { success, message: errorMsg, error: zodErr, statusCode } = resp;
  const { libraryMode, source: configSource, dbPath } = configSourceObj || {};
  if (success) {
    const msgTitle = 'Config saved!';
    if (!libraryMode && configSource !== 'db') {
      const msg: React.ReactNode = (
        <div>
          Database path: "<strong>{dbPath}</strong>".
          <br />
          <br />
          Please make sure using the
          <br />
          <code>AF_DB_PATH={dbPath}</code>
          <br />
          env variable next time you start Authfish!
        </div>
      );
      modal.success({
        title: msgTitle,
        content: msg
      });
    } else {
      showConfigFormMessage(message, msgTitle);
    }
  } else {
    if (statusCode === 401) {
      showConfigFormMessage(message, 'Authorization failed, please re-login', 'error');
      await new Promise((resolve) => setTimeout(resolve, 2000));
      afLogoutRedirect(LOGOUT_ROUTE);
    } else if (zodErr) {
      // setFields could be related to the fields that aren't shown
      showZodErrors(zodErr, form, message);
    } else if (errorMsg) {
      const msg = `Error saving config: ${errorMsg}`;
      showConfigFormMessage(message, msg, 'error');
    }
  }
}

export default showSaveConfigResult;
