import { FormInstance } from 'antd';
import { dump } from 'js-yaml';
import { MessageInstance } from 'antd/lib/message/interface';

import type { AuthfishConfig } from 'shared/authfishConfig';
import saveFile from '../../importExport/saveFile';
import configEncryptedFromFormValues from '../../../api/configEncryptedFromFormValues';
import { showZodErrors } from '../../formElements/utilities/showError';
import getConfigHandler from '../../../api/getConfigHandler';
import showConfigLoadedMsg from './showConfigLoadedMsg';
import formatDate from '../../../lib/formatDate';
import showConfigFormMessage from './showConfigFormMessage';

async function configFromForm(
  form: FormInstance,
  config: AuthfishConfig,
  message?: MessageInstance
): Promise<AuthfishConfig | undefined> {
  // we won't need preValidateFields since we disable DropdownMenu with SubmitButton
  const formValues = form.getFieldsValue();
  const [newConfig, validationError] = await configEncryptedFromFormValues(
    formValues,
    config,
    message
  );
  if (newConfig) {
    return newConfig;
  } else {
    showZodErrors(validationError, form, message);
  }
}

async function dropdownOnClick(
  form: FormInstance,
  config: AuthfishConfig,
  { key }: { key: string }, // MenuInfo
  startImportFunc: () => void,
  message?: MessageInstance
) {
  let newConfig: AuthfishConfig | undefined | null;
  let errorMsg: string | null;
  switch (key) {
    case 'export':
      newConfig = await configFromForm(form, config, message);
      if (newConfig) await saveFile(dump(newConfig));
      break;
    case 'import':
      // due to Upload implementation in AntD we can't control the process from here | see processUpload
      startImportFunc();
      break;
    default: // loading config by TS, key is stringified TS
      ({ config: newConfig, errorMsg } = await getConfigHandler(key));
      if (newConfig) form.setFieldsValue(newConfig);
      if (message) {
        if (errorMsg) showConfigFormMessage(message, errorMsg, 'error');
        else showConfigLoadedMsg(message, `database: ${formatDate(new Date(parseInt(key)))}`);
      }
      break;
  }
}

export default dropdownOnClick;
