import { z } from 'zod';

import { DefaultValueTypes } from '../types/FormFields';
import {
  getUnderlyingSchema,
  isZodArray,
  isZodDefault,
  isZodEffects,
  isZodEnum,
  isZodOptional
} from './inferFieldType';

export function inferOptions(fieldSchema: z.ZodTypeAny): string[] | undefined {
  const underlyingSchema = getUnderlyingSchema(fieldSchema);
  // for the select-type fields we'll need the options for the dropdown
  // so if there are enums we'll use these values
  if (isZodEnum(underlyingSchema)) {
    return underlyingSchema._def.values as string[];
  } else if (isZodArray(underlyingSchema)) {
    // multiSelect
    return underlyingSchema.element._def.values as string[];
  }
  // for now we don't use the literals in AuthfishConfig
  return undefined;
}

export function inferDefaultValue(fieldSchema: z.ZodTypeAny): DefaultValueTypes | undefined {
  if (isZodDefault(fieldSchema)) {
    return fieldSchema._def.defaultValue();
  } else if (isZodEffects(fieldSchema)) {
    return inferDefaultValue(fieldSchema._def.schema);
  } else if (isZodOptional(fieldSchema)) {
    return inferDefaultValue(fieldSchema._def.innerType);
  }
  return undefined;
}

// objects | arrays | primitives
type schemaShape = Record<string, z.ZodTypeAny> | undefined;

export function inferShape(schema: z.ZodTypeAny): schemaShape {
  // .refine() makes it impossible to access .shape normally
  // https://github.com/colinhacks/zod/issues/2474
  // https://github.com/colinhacks/zod/issues/2056
  const iSchema = schema._def;
  switch (iSchema.typeName) {
    case 'ZodArray':
      // shape only exists for the objects, so here we should handle things differently
      return undefined;
    case 'ZodObject':
      return (schema as z.ZodObject<any>).shape;
    case 'ZodUnion':
      // similarly to inferFieldType we need to infer the 1st element type
      return inferShape(iSchema.options[0]);
    default:
      // here we may have endless recursion for primitives, hence double-check
      const ultimateType = getUnderlyingSchema(schema);
      return ultimateType === schema ? undefined : inferShape(ultimateType);
  }
}
