import React, { useState, useEffect } from 'react';
import type { FormInstance } from 'antd';
import { Button, Form } from 'antd';

// very similar to SubmitButton nxt => create generic FormButton
interface resetButtonProps {
  form: FormInstance;
  caption?: string;
  dataTestID?: string;
  icon?: React.ReactNode;
}

const ResetButton = ({
  form,
  caption = 'Reset',
  dataTestID = 'resetButton',
  icon = undefined
}: resetButtonProps) => {
  const [valuesChanged, setValuesChanged] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    setValuesChanged(form.isFieldsTouched());
  }, [form, values]);

  return (
    <Button
      type="default"
      htmlType="reset"
      icon={icon}
      disabled={!valuesChanged}
      onClick={() => form.resetFields()}
      data-testid={dataTestID}
    >
      {caption}
    </Button>
  );
};

export default ResetButton;
