import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { APP_LOGIN_PATH, APP_CONFIG_PATH } from 'shared/routes';
import { appLoginCookieName } from 'shared/cookies';
import LoginPage from './pages/LoginPage';
import ConfigPage from './pages/config/ConfigPage';

function splitPath(fullPath: string): [string, string] {
  const pathSplit = fullPath.split('/');
  return [`/${pathSplit[1]}`, pathSplit[2]];
}

const [loginRoute, loginPageRoute] = splitPath(APP_LOGIN_PATH);
const [configRoute, configPageRoute] = splitPath(APP_CONFIG_PATH);

export const localDev = process.env.NODE_ENV === 'development';

// config for the login page
const loginConfig = JSON.parse(Cookies.get(appLoginCookieName) || '{}');
Cookies.remove(appLoginCookieName);

function createAppRouter(loginConfig?: object) {
  if (!loginConfig) loginConfig = {};
  const routes = [
    {
      path: loginRoute, // login
      children: [
        {
          path: loginPageRoute, // app_login
          element: <LoginPage {...loginConfig} />
        }
      ],
      errorElement: localDev ? <LoginPage {...loginConfig} /> : undefined
    },
    {
      path: configRoute, // auth
      children: [
        {
          path: configPageRoute, // config
          element: <ConfigPage />
        }
      ]
    }
  ];
  if (localDev) {
    // redirecting back to the login page after logging in
    routes.push({
      path: '/',
      children: [
        {
          path: '/',
          element: <Navigate to={APP_LOGIN_PATH} replace />
        }
      ],
      errorElement: <LoginPage {...loginConfig} />
    });
  }
  return createBrowserRouter(routes);
}

function App() {
  return <RouterProvider router={createAppRouter(loginConfig)} />;
}

export default App;
