import React, { useState, memo, useRef, Suspense, useMemo } from 'react';
import { Form, Spin, Flex, App as AntdApp, FormInstance } from 'antd';
import { SaveOutlined, ReloadOutlined } from '@ant-design/icons';

import styles from '../../pages/config/ConfigPage.module.scss';

import { AuthfishConfig, configSchema } from 'shared/authfishConfig';
import type { ProtectedSettings } from 'shared/AuthfishParams';
import { FormContext } from './useFormContext';
import generateFormFields from '../../configSchemas/generateFormFields';
import FormItems from './FormItems';
import filterFormFields from '../../configSchemas/filterFormFields';
import { pageTitle } from './helpers/formTexts';
import SubmitButton from '../formElements/SubmitButton';
import ResetButton from '../formElements/ResetButton';
import validateAndSaveConfig, { validateConfigResp } from '../../api/validateAndSaveConfig';
import preValidateFields from './helpers/preValidateFields';
import { configSourceData } from '../../api/getConfigHandler';
import showSaveConfigResult from './helpers/showSaveConfigResult';
import { FieldCategories } from '../../types/FormFields';
import { ActivationKeyManagement } from './customElements';
import DropdownMenu from './DropdownMenu';
import ConfigUploader from './ConfigUploader';
import { isEmpty } from 'lodash';

const allItemsMapping = (protectedSettings: ProtectedSettings[]) =>
  generateFormFields(configSchema, protectedSettings);

interface configFormProps {
  config: AuthfishConfig;
  setConfig: (config: AuthfishConfig) => void;
  activeLabel?: FieldCategories;
  timestampsAvailable: number[];
  addTimestamp: (timestamp: number) => void;
  protectedSettings: ProtectedSettings[];
  configSourceObj?: configSourceData;
}

const ConfigForm = ({
  config,
  setConfig,
  activeLabel,
  timestampsAvailable,
  addTimestamp,
  protectedSettings,
  configSourceObj
}: configFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm<AuthfishConfig>();

  const { message, modal } = AntdApp.useApp();

  // it seems the only way to use the Upload without a Button is implementing an invisible one
  const uploadBtnRef = useRef<HTMLButtonElement>(null);
  const startImportFunc = () => {
    uploadBtnRef.current?.click();
  };

  const onFinish = async (formValues: AuthfishConfig) => {
    setLoading(true);
    // console.log(formValues);
    const validateResp: validateConfigResp = await validateAndSaveConfig(
      config,
      setConfig,
      addTimestamp,
      formValues,
      configSourceObj
    );
    await showSaveConfigResult(validateResp, configSourceObj, form, message, modal);
    setLoading(false);
  };

  const itemsMapping = filterFormFields(allItemsMapping(protectedSettings), activeLabel);

  const isEmptyConfig = isEmpty(itemsMapping);

  const preValidateCallBack = (
    // setting default values when undefined
    formInstance: FormInstance,
    values: Record<string, any>
  ) => preValidateFields(itemsMapping, formInstance, values);

  const dropDownCommonProps = {
    form,
    config,
    startImportFunc,
    setLoading
  };

  const customContentRender = useMemo(() => {
    switch (activeLabel) {
      case 'Activation key': {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ActivationKeyManagement configSourceObj={configSourceObj} config={config} />
          </Suspense>
        );
      }

      default:
        return null;
    }
  }, [activeLabel, config, configSourceObj]);

  return (
    <FormContext.Provider value={form}>
      <Form
        form={form}
        name="config"
        initialValues={config}
        onFinish={onFinish}
        data-testid="configForm"
      >
        <Spin size={'large'} spinning={loading}>
          {
            !isEmptyConfig && <>
              <h1>{pageTitle(activeLabel)}</h1>
              <FormItems itemsMapping={itemsMapping} config={config}/>
              <Form.Item className={'formButtons'}>
                <Flex justify={'space-between'}>
                  <Flex justify={'space-evenly'} className={styles.dropButton}>
                    <ResetButton
                      form={form}
                      icon={<ReloadOutlined/>}
                      dataTestID={'resetConfigButton'}
                    />
                    <DropdownMenu
                      {...dropDownCommonProps}
                      type={'reset'}
                      dataTestID={'resetDropDown'}
                      timestampsAvailable={timestampsAvailable}
                    />
                  </Flex>
                  <Flex justify={'space-evenly'} className={styles.dropButton}>
                    <SubmitButton
                      form={form}
                      caption={'Save'}
                      icon={<SaveOutlined/>}
                      dataTestID={'saveConfigButton'}
                      preValidateCallBack={preValidateCallBack}
                      ExtraElement={
                        <DropdownMenu
                          {...dropDownCommonProps}
                          type={'save'}
                          dataTestID={'saveDropDown'}
                        />
                      }
                    />
                  </Flex>
                </Flex>
              </Form.Item>
            </>
          }

          {customContentRender}
          <ConfigUploader
            form={form}
            uploadBtnRef={uploadBtnRef}
            setLoading={setLoading}
            setConfig={setConfig}
          />
        </Spin>
      </Form>
    </FormContext.Provider>
  );
};

export default memo(ConfigForm);
