import { FormField } from '../../../types/FormFields';
import { passwordLabel } from './renderInputElement';

function formItemPasswordForm(
  item: FormField,
  passwordIndex: number,
  updateMode: boolean
): FormField {
  const isRequired = !updateMode;
  const label = passwordLabel(item);
  const fullLabel = updateMode ? `New ${label.toLowerCase()}` : label;
  return {
    key: `${item.key}__${passwordIndex}`,
    type: 'password',
    required: isRequired,
    label: passwordIndex === 0 ? fullLabel : 'Confirmation',
    defaultValue: ''
  };
}

function formItemsPasswords(item: FormField, updateMode: boolean): FormField[] {
  // here we should create 2 items for the password & confirm p
  return [0, 1].map((passwordIndex) => formItemPasswordForm(item, passwordIndex, updateMode));
}

export default formItemsPasswords;
