import React from 'react';
import { Button, Modal } from 'antd';
import { deleteActivationKey } from '../../../api/handleActivationKey';
import { configSourceData } from '../../../api/getConfigHandler';
import { useModalActions } from './useModalActions';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleNotifyAndLogout: () => void;
  configSourceObj: configSourceData | undefined;
}

export const DeleteActivationKeyModal = ({
  open,
  handleClose,
  handleNotifyAndLogout,
  configSourceObj
}: Props) => {
  const { loading, handleModalAction } = useModalActions<typeof deleteActivationKey>({
    handleClose,
    handleNotifyAndLogout,
    libraryMode: configSourceObj?.libraryMode,
    action: () => deleteActivationKey()
  });

  return (
    <Modal
      title="Delete Activation Key"
      open={open}
      onOk={handleModalAction}
      onCancel={loading ? undefined : handleClose}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Button type="primary" onClick={handleModalAction} loading={loading}>
            Delete
          </Button>
        </>
      )}
    >
      <p>Are you sure you want to delete activation key?.</p>
    </Modal>
  );
};
