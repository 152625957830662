import React, { useState, useEffect, forwardRef, useCallback, useMemo } from 'react';
import { Select, Input } from 'antd';
import debounce from 'lodash/debounce';

type HandleProtocolChange = (newProtocol: string | undefined) => void;
type SelectAddon = string | React.ReactNode | undefined;

export type ProtocolsTuple = [string[], number];
// [protocols], default index

export function supportedProtocols(protocolsTuple: ProtocolsTuple): [string[], string] {
  const protocols = protocolsTuple[0];
  const defaultProtocol = protocols[protocolsTuple[1]];
  return [protocols, defaultProtocol];
}

// type BaseInputProps = React.HTMLAttributes<HTMLDivElement> // no disabled & readOnly
type BaseInputProps = React.InputHTMLAttributes<HTMLInputElement>;

// if rename onChange it'll break functionality hence omitting default implementation
interface UrlInputProps extends Omit<BaseInputProps, 'onChange' | 'size'> {
  value?: string; // will be passed from the Form.Item
  onChange?: (value: string | undefined) => void; // ...also from the Form.Item
  protocolsTuple?: [string[], number];
}

function generateURLAddon(
  protocols: string[],
  defaultProtocol: string,
  currentValue: string | undefined,
  handleProtocolChange: HandleProtocolChange
): SelectAddon {
  if (protocols.length === 1) return defaultProtocol;
  const formattedDefaultProtocol = `${defaultProtocol}://`;
  return (
    <Select
      defaultValue={formattedDefaultProtocol}
      value={currentValue || formattedDefaultProtocol}
      data-testid="URLInputSelect"
      onChange={handleProtocolChange}
    >
      {protocols.map((val) => {
        const valProtocol = `${val}://`;
        return (
          <Select.Option value={valProtocol} key={`select_protocol_${val}`}>
            {valProtocol}
          </Select.Option>
        );
      })}
    </Select>
  );
}

function handleInput(
  inputValue: string,
  protocols: string[],
  setProtocol: HandleProtocolChange,
  setPath: (path: string) => void,
  setDefaultProtocol: () => void
) {
  if (inputValue) {
    let [extractedProtocol, ...rest] = inputValue.split('://');
    // if the user pasted the URL, we may have protocol in rest as well!
    if (rest.length > 1) {
      extractedProtocol = rest[0];
      rest = rest.slice(1);
    }
    if (protocols.includes(extractedProtocol)) {
      setProtocol(`${extractedProtocol}://`);
      setPath(rest.join('://'));
    } else {
      console.warn(`URLInput | protocol: ${extractedProtocol} in not supported: ${protocols}`);
      // not sure what's the most proper way but like so we won't get endless reloads
      setDefaultProtocol();
      setPath(rest.join(''));
    }
  } else {
    setDefaultProtocol();
  }
}

const URLInput = forwardRef((props: UrlInputProps, _ref) => {
  // could add useImperativeHandle if need more control

  const { value = '', onChange, protocolsTuple = [['https'], 0], ...rest } = props;
  const [protocols, defaultProtocol] = supportedProtocols(protocolsTuple);

  const [protocol, setProtocol] = useState<string | undefined>(defaultProtocol);
  const [path, setPath] = useState<string | undefined>(undefined);

  const setDefaultProtocol = useCallback(
    () => setProtocol(`${defaultProtocol}://`),
    [defaultProtocol]
  );

  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: string | undefined) => {
        onChange?.(newValue);
      }, 500),
    [onChange]
  );

  useEffect(() => {
    handleInput(value, protocols, setProtocol, setPath, setDefaultProtocol);
  }, [value, protocols, setProtocol, setPath, setDefaultProtocol]);

  useEffect(() => {
    // avoiding premature validation trigger
    if (path !== undefined) {
      // make sure we allow to clear the input!
      const newValue = path === '' ? undefined : `${protocol}${path}`;
      debouncedOnChange(newValue);
    }
  }, [protocol, path, debouncedOnChange]);

  const handlePathChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPath = e.target.value;
    setPath(newPath);
  };

  const selectAddon: SelectAddon = generateURLAddon(
    protocols,
    defaultProtocol,
    protocol,
    setProtocol
  );

  return (
    <Input
      value={path}
      onChange={handlePathChange}
      data-testid="URLInput"
      addonBefore={selectAddon}
      {...rest}
    />
  );
});

export default URLInput;
