import formatDate from '../../lib/formatDate';

// https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker
// showSaveFilePicker works in most of the browsers when not in an iframe
// although could try this: allow="file-system-access"
const envSupportsFileSystemAccess =
  'showSaveFilePicker' in window &&
  (() => {
    try {
      return window.self === window.top;
    } catch {
      return false;
    }
  })();

const downloadWithDialog = async (
  blob: Blob,
  suggestedName: string,
  fileExt?: string,
  fileType?: string
) => {
  const acceptedFileDesc = `${fileType || 'text/plain'}`;
  const acceptedExt = `.${fileExt || 'txt'}`;
  const accept = {
    [acceptedFileDesc]: [acceptedExt]
  };
  try {
    const handle = await window.showSaveFilePicker({
      suggestedName,
      types: [
        {
          description: `${acceptedFileDesc} as ${acceptedExt}`,
          accept
        }
      ]
    });
    const writable = await handle.createWritable();
    await writable.write(blob);
    await writable.close();
    return;
  } catch (err: unknown) {
    // if the user canceled the dialog.
    if (err instanceof DOMException && err.name === 'AbortError') {
      console.log(err.name, err.message);
      return;
    }
    console.error(err);
  }
};

// hacky, but working way to download the file that works for any browser | env
const triggerDownload = (url: string, filename: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

async function saveFile(
  content: string,
  filename: string = 'config.yaml',
  blobType: string = 'text/yaml;charset=utf-8'
): Promise<void> {
  const blob = new Blob([content], { type: blobType });
  // let's add date & time to the filename
  const dateStr = formatDate();
  const suggestedFName = `${filename.split('.')[0]} ${dateStr}`;
  const fileExt = filename.split('.').pop();
  const fileType = blobType.split(';')[0];
  if (envSupportsFileSystemAccess) {
    await downloadWithDialog(blob, suggestedFName, fileExt, fileType);
  } else {
    const url = window.URL.createObjectURL(blob);
    triggerDownload(url, `${suggestedFName}.${fileExt}`);
  }
}

export default saveFile;
