import { FormField } from '../../../types/FormFields';

function isItemRequired(item: FormField): boolean {
  // makes no sense displaying * for checkboxes since they are false by def
  if (item.type === 'checkbox') return false;
  if (item.parentSchema && !item.parentSchema.required) {
    // for items with ParentSchema, if the parent is optional vs required item
    // the item is required only with siblings present
    return false;
  }
  return item.required;
}

export default isItemRequired;
