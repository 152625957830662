import { ActivationKeyWithOrigin } from 'shared/activationKey';



interface handleActivationKeyResponse {
  success: boolean;
  data: ActivationKeyWithOrigin;
  status: number;
}

const activationKeyUrl = '/activationKey';

export async function getActivationKey(): Promise<handleActivationKeyResponse> {
  try {
    const response = await fetch(activationKeyUrl);
    const data = await response.json();

    return {
      success: true,
      data,
      status: response.status
    };
  } catch (error: unknown) {
    throw new Error();
  }
}

export async function saveActivationKey(value: { activationKey: string }) {
  try {
    const response = await fetch(`${activationKeyUrl}?overwrite=true`, {
      body: JSON.stringify(value),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    });

    if (!response.ok) {
      const error = await response.json();
      return { status: 'error', error: error.message };
    }

    return { status: 'success' };
  } catch (error: unknown) {
    console.error(error);
    return { status: 'error', error: 'Something went wrong. Try again later' };
  }
}

export async function deleteActivationKey() {
  try {
    const response = await fetch(`${activationKeyUrl}`, {
      method: 'DELETE'
    });

    if (!response.ok) {
      const error = await response.json();
      return { status: 'error', error: error.message };
    }

    return { status: 'success' };
  } catch (error: unknown) {
    console.error(error);
    return { status: 'error', error: 'Something went wrong. Try again later' };
  }
}
