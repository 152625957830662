import React from 'react';
import { MessageInstance } from 'antd/lib/message/interface';
import { NoticeType } from 'antd/es/message/interface';

import iFramedMode from './iFramedMode';

function showConfigFormMessage(
  message: MessageInstance,
  msg: string | React.ReactNode,
  msgType: NoticeType = 'success',
  duration: number = 3
) {
  // tried using message config with getContainer, but we can't get
  // parent page element from here; and position: "sticky" won't work in iFramed mode
  // so the only solution so far is to show the messages on bottom; although it's a bit weird
  message
    .open({
      type: msgType,
      content: msg,
      className: iFramedMode ? 'msgToBottom' : undefined,
      duration
    })
    .then();
}

export default showConfigFormMessage;
