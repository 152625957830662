import Cookies from 'js-cookie';

import { APP_CONFIG_PATH, LOGOUT_ROUTE } from 'shared/routes';
import { destinationCookieName } from 'shared/cookies';

export function setDestinationConfigCookie(): void {
  Cookies.set(destinationCookieName, APP_CONFIG_PATH);
}

function afLogoutRedirect(
  redirectURL: string = LOGOUT_ROUTE,
  destinationConfig: boolean = true
): void {
  // redirect to the Logout URL + setting a cookie for afLoginRedirect
  if (destinationConfig) setDestinationConfigCookie();
  window.location.replace(redirectURL);
}

export default afLogoutRedirect;
