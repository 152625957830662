async function sendPost(endpointURL: string, reqBody: Record<string, any>) {
  return await fetch(endpointURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  });
}

export default sendPost;
