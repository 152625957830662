import { z } from 'zod';
import { FormFieldType, inputTypes } from '../types/FormFields';

// fix since "instanceof z.XXX" doesn't work so well in various cases
function checkTypeName(fieldSchema: z.ZodTypeAny, targetTypeName: string): boolean {
  return fieldSchema._def.typeName === targetTypeName;
}

export function isZodArray(fieldSchema: z.ZodTypeAny): fieldSchema is z.ZodArray<any> {
  return checkTypeName(fieldSchema, 'ZodArray');
}

export function isZodEnum(fieldSchema: z.ZodTypeAny): fieldSchema is z.ZodEnum<any> {
  return checkTypeName(fieldSchema, 'ZodEnum');
}

export function isZodDefault(fieldSchema: z.ZodTypeAny): fieldSchema is z.ZodDefault<any> {
  return checkTypeName(fieldSchema, 'ZodDefault');
}

export function isZodEffects(fieldSchema: z.ZodTypeAny): fieldSchema is z.ZodEffects<any> {
  return checkTypeName(fieldSchema, 'ZodEffects');
}

export function isZodObject(fieldSchema: z.ZodTypeAny): fieldSchema is z.ZodObject<any> {
  return checkTypeName(fieldSchema, 'ZodObject');
}

export function isZodOptional(fieldSchema: z.ZodTypeAny): fieldSchema is z.ZodOptional<any> {
  return checkTypeName(fieldSchema, 'ZodOptional');
}

export function getUnderlyingSchema(fieldSchema: z.ZodTypeAny): z.ZodTypeAny {
  // finding out what's the type behind Effects / Default etc
  const iSchema = fieldSchema._def; // inner schema definition
  switch (iSchema.typeName) {
    case 'ZodDefault':
    case 'ZodOptional':
    case 'ZodNullable':
      return getUnderlyingSchema(iSchema.innerType);
    case 'ZodEffects':
      return getUnderlyingSchema(iSchema.schema);
    default:
      return fieldSchema;
  }
}

function inferStringType(iSchema: Record<string, any>): FormFieldType {
  if (iSchema.checks && Array.isArray(iSchema.checks) && iSchema.checks.length) {
    const isURL = iSchema.checks.some((check) => check.kind === 'url');
    if (isURL) return 'url';
  }
  return 'text';
}

/**
 * Infers the field type based on the given field schema.
 *
 * @param {z.ZodTypeAny} fieldSchema - The field schema to infer the type from.
 * @return {FormFieldType | undefined} The inferred field type, or undefined
 * if the type cannot be inferred e.g. if the field schema is an Object
 */
export function inferFieldType(fieldSchema: z.ZodTypeAny): FormFieldType | undefined {
  const iSchema = fieldSchema._def;
  // (1) we infer the types for primitive structures | early exit for Objects
  const flatFieldTypeMap: Record<string, FormFieldType | null | undefined> = {
    ZodString: inferStringType(iSchema),
    ZodNumber: 'number',
    ZodBoolean: 'checkbox',
    ZodEnum: 'select',
    ZodObject: null // we'll return undefined for nested objects
  };
  // (2) we infer the types for nested structures
  const simpleFieldType = flatFieldTypeMap[iSchema.typeName];
  if (simpleFieldType !== undefined) return simpleFieldType || undefined;
  // and now for more complex constructions we infer based on the underlying type
  switch (iSchema.typeName) {
    case 'ZodArray':
      const aTypes = [...inputTypes, 'select'];
      return isZodArray(fieldSchema) && aTypes.includes(inferFieldType(fieldSchema.element) || '')
        ? 'multiSelect'
        : undefined;
    case 'ZodUnion':
      // Unions could be complex, so we just infer the 1st element schema,
      // and let ZOD validation handle the rest on submit
      return inferFieldType(iSchema.options[0]);
    default:
      return inferFieldType(getUnderlyingSchema(fieldSchema));
  }
}
