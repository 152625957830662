import { Rule } from 'antd/lib/form';

import { FormFieldType } from '../../../types/FormFields';

function requiredPrefix(fieldType: FormFieldType = 'text') {
  return fieldType !== 'select' ? 'Please input' : 'Please select';
}

function validationRules(
  fieldLabel: string,
  fieldType: FormFieldType = 'text',
  isRequired: boolean = false,
  labelPrefix: string = ''
): Rule[] {
  const rules: Rule[] = [];
  if (isRequired) {
    const requiredPre = requiredPrefix(fieldType);
    const msgPrefix = labelPrefix ? `${requiredPre} ${labelPrefix}` : requiredPre;
    rules.push({ required: true, message: `${msgPrefix} ${fieldLabel}` });
  }
  return rules;
}

export default validationRules;
