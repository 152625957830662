import React from 'react';
import { FormInstance } from 'antd';

import { hasAuthLocalUsers } from '../../types/afConfig';
import useFormContext from './useFormContext';
import { FormField } from '../../types/FormFields';
import formItemsPasswords from './helpers/formItemsPasswords';
import renderFormItem from './helpers/renderFormItem';
import confirmPasswordValidator from './helpers/confirmPasswordValidator';
import { namePathForUserPassword, namePathForSecret } from './helpers/formKeys';
import getLocalUsers from 'shared/getLocalUsers';

function userPasswordHash(form: FormInstance | undefined, userIndex: number): string | undefined {
  if (!form) return undefined;
  const formValues = form.getFieldsValue();
  // assuming that we could only set local users
  if (hasAuthLocalUsers(formValues)) {
    const localUsers = getLocalUsers(formValues);
    if (localUsers.length > userIndex) {
      return localUsers[userIndex].password_hash;
    }
  }
  return undefined;
}

interface PasswordsFormProps {
  item: FormField;
}

const Passwords = ({ item }: PasswordsFormProps) => {
  const configForm = useFormContext();

  const userIndex = parseInt(item.key.split('.')[0]);
  const isUserPassword = !isNaN(userIndex);

  // update -vs- add mode depending on if we already have a hash
  const updateMode: boolean = isUserPassword
    ? !!userPasswordHash(configForm, userIndex)
    : !!configForm.getFieldValue(item.key.split('.'));
  const formItems = formItemsPasswords(item, updateMode); // here we add __0 & __1

  const passwordsValidator = confirmPasswordValidator(item, userIndex);
  const dependencies = [
    isUserPassword ? namePathForUserPassword(userIndex) : namePathForSecret(item)
  ];

  return <>{formItems.map((item) => renderFormItem(item, passwordsValidator, dependencies))}</>;
};

export default Passwords;
