import { FieldCategories, FormField, FormFieldType } from '../../../types/FormFields';
import { labelsMapping } from '../SiderComponent';
import { getSubKey } from './formKeys';
import { capitalize } from '../../../lib/formLabels';

function checkBoxLabel(key: string, label: string) {
  // by default these could be just "Enabled", let's add Key before last
  const keyEnd = getSubKey(key, 2);
  const oneWordLabel = label.split(' ').length === 1;
  return keyEnd && oneWordLabel ? `${capitalize(keyEnd)} ${label.toLowerCase()}` : label;
}

export function getItemLabelFull(itemType: FormFieldType | undefined, key: string, label: string) {
  switch (itemType) {
    case 'checkbox':
      return checkBoxLabel(key, label);
    default:
      return label;
  }
}

export function getKeyPrefix(fullKey: string, joinStr: string = ' '): string | undefined {
  // e.g. "key.sub.item" => "key sub"
  const keySplit = fullKey.split('.');
  return keySplit.length > 1 ? keySplit.slice(0, keySplit.length - 1).join(joinStr) : undefined;
}

export function filterByKeyPrefix(itemsMapping: FormField[], keyPrefix: string): FormField[] {
  return itemsMapping.filter((item) => {
    return getKeyPrefix(item.key) === keyPrefix;
  });
}

export function pageTitle(activeLabel: FieldCategories | undefined) {
  if (!activeLabel) return 'Loading...';
  let topLevelInject = ``;
  labelsMapping.forEach((labelMapping) => {
    const { category, children } = labelMapping;
    if (children && children.some((child) => child.category === activeLabel)) {
      topLevelInject = `${category} `;
    }
  });
  return `${activeLabel} ${topLevelInject}Settings`;
}
