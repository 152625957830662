import { Divider } from 'antd';
import React from 'react';

interface dividerProps {
  // keyPrefix: string // won't work
  label?: string;
  dataTestID?: string;
}

function FormDivider({ label, dataTestID }: dividerProps): React.JSX.Element {
  return (
    <Divider orientation="left" orientationMargin="0" data-testid={dataTestID || 'formDivider'}>
      {label}
    </Divider>
  );
}

export default FormDivider;
