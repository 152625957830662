import React from 'react';
import { awaitServerReady } from '../../../api/handleConfig';
import { message } from 'antd';

export const useModalActions = <T extends (...args: any[]) => any>({
  handleClose,
  libraryMode,
  handleNotifyAndLogout,
  action
}: {
  handleClose: () => void;
  libraryMode?: boolean;
  handleNotifyAndLogout: () => void;
  action: T;
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleModalAction = async (value?: unknown) => {
    setLoading(true);

    await awaitServerReady();

    const data = await action(value);

    // turned out this ↘️️ causes problems when used with Next in dev mode
    if (!libraryMode) await awaitServerReady();
    else await new Promise((resolve) => setTimeout(resolve, 5000));

    setLoading(false);

    if (data.status === 'error') {
      message.error(data.error);
      return;
    }

    if (!loading) {
      handleClose();
    }

    handleNotifyAndLogout();
  };

  return { loading, handleModalAction };
};
