import { ZodError } from 'zod';

import { AuthfishConfig } from 'shared/authfishConfig';
import { saveConfig } from './handleConfig';
import configEncryptedFromFormValues from './configEncryptedFromFormValues';
import { isErrorResponse } from 'shared/responses';
import { configSourceData } from './getConfigHandler';

export interface validateConfigResp {
  success: boolean;
  message?: string;
  error?: ZodError;
  statusCode?: number;
}

async function validateAndSaveConfig(
  config: AuthfishConfig,
  setConfig: (config: AuthfishConfig) => void,
  addTimestamp: (timestamp: number) => void,
  newValues: Record<string, unknown>,
  configSourceObj?: configSourceData
): Promise<validateConfigResp> {
  const [newConfig, validationError] = await configEncryptedFromFormValues(newValues, config);
  if (newConfig) {
    const { success, response, status } = await saveConfig(
      newConfig,
      addTimestamp,
      configSourceObj?.libraryMode
    );
    if (success && !isErrorResponse(response)) {
      // newConfig has the secrets encrypted, while in form we need them decrypted so we use response
      setConfig(response.config);
      return { success: true };
    } else {
      const errorStr = JSON.stringify(response);
      console.error(`validateConfig | saveConfig: ${errorStr}`);
      return {
        success: false,
        message: errorStr,
        statusCode: status
      };
    }
  } else {
    console.log(JSON.stringify(newValues));
    console.warn(`validateConfig: ${validationError}`);
    return { success: false, error: validationError };
  }
}

export default validateAndSaveConfig;
