import React from 'react';
import { Menu } from 'antd';
import {
  SettingOutlined,
  CloudServerOutlined,
  LockOutlined,
  UsergroupAddOutlined,
  SignatureOutlined
} from '@ant-design/icons';
import getMenuItems, { labelMapping, OnItemClickHandler } from './helpers/getMenuItems';
import {FieldCategories} from "../../types/FormFields";

const authLabelsMapping: labelMapping<FieldCategories>[] = [
  { category: 'Local' },
  { category: 'LDAP' },
  { category: 'SAML' },
  { category: 'OIDC' }
];
export const labelsMapping: labelMapping<FieldCategories>[] = [
  { category: 'General', icon: <SettingOutlined /> },
  { category: 'Server', icon: <CloudServerOutlined /> }, //  (http, certs)
  { category: 'Activation key', icon: <SignatureOutlined /> },
  { category: 'Auth', icon: <LockOutlined />, children: authLabelsMapping },
  // _TODO: temp disabled Roles, need to add it
  { category: 'Roles', icon: <UsergroupAddOutlined />, isDisabled: true }
];

interface SiderComponentProps {
  onItemClickHandler: OnItemClickHandler<FieldCategories>;
}

const SiderComponent: React.FC<SiderComponentProps> = ({ onItemClickHandler }) => {
  // light theme should be defined here + for Sider in ConfigPage
  return (
    <Menu
      theme="light"
      // onClick={...} // here we only can get item key
      defaultSelectedKeys={['menu_item_1']}
      defaultOpenKeys={['menu_item_3']}
      mode="inline"
      items={getMenuItems(labelsMapping, undefined, onItemClickHandler)}
    />
  );
};

export default React.memo(SiderComponent);
