const secretFields = ['decryption_pvk', 'bindCredentials', 'secret']; // , 'clientSecret'
const passwordFields = ['password_hash'];

function keyInFields(key: string, fields: string[]): boolean {
  return fields.some((f) => key.toLowerCase().includes(f.toLowerCase()));
}

export function isPasswordField(key: string): boolean {
  return keyInFields(key, passwordFields);
}

export function isSecretField(key: string): boolean {
  return keyInFields(key, secretFields);
}

const secretOrPasswordFieldNames = [...secretFields, ...passwordFields];

function hashedOrEncryptedFields(key: string): boolean {
  // for the fields containing passwords | secrets, etc we'll need to hash/encrypt them
  return keyInFields(key, secretOrPasswordFieldNames);
}

export default hashedOrEncryptedFields;
