import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { App as AntdApp, Dropdown, FormInstance } from 'antd';
import type { MenuInfo } from 'rc-menu/lib/interface';

import type { AuthfishConfig } from 'shared/authfishConfig';
import { dropdownItemsReset, dropdownItemsSave } from './helpers/dropdownItems';
import dropdownOnClick from './helpers/dropdownOnClick';

import styles from './DropdownMenu.module.scss';

interface DropDownMenuProps {
  type: 'save' | 'reset';
  form: FormInstance;
  config: AuthfishConfig;
  startImportFunc: () => void;
  timestampsAvailable?: number[];
  setLoading?: (loading: boolean) => void;
  disabled?: boolean;
  dataTestID?: string;
}

const DropdownMenu = ({
  type,
  form,
  config,
  setLoading,
  startImportFunc,
  timestampsAvailable = [],
  disabled = false,
  dataTestID = 'DropdownMenu'
}: DropDownMenuProps) => {
  const { message } = AntdApp.useApp();

  const dropDownOnClickHandler = async (info: MenuInfo) => {
    if (setLoading) setLoading(true);
    await dropdownOnClick(form, config, info, startImportFunc, message);
    if (setLoading) setLoading(false);
  };

  return (
    <>
      <Dropdown
        menu={{
          items: type === 'save' ? dropdownItemsSave() : dropdownItemsReset(timestampsAvailable),
          onClick: dropDownOnClickHandler
        }}
        trigger={['click']}
        placement={'bottomRight'}
        disabled={disabled}
        className={disabled ? 'disabled_element' : ''}
        overlayClassName={styles.dropdown}
        // data-testid won't work here
        arrow
      >
        <DownOutlined data-testid={dataTestID} />
      </Dropdown>
    </>
  );
};
export default DropdownMenu;
