import isObject from './isObject';

export function mergeConfigsCustomizer(objValue: unknown, srcValue: unknown): unknown {
  function areNestedObjsBelow(
    keys: string[],
    obj1: Record<string, unknown>,
    obj2: Record<string, unknown>
  ): boolean {
    return keys.some((k) => isObject(obj1[k]) || isObject(obj2[k]));
  }

  if (Array.isArray(srcValue)) {
    return srcValue;
  } else if (isObject(srcValue) && isObject(objValue)) {
    const result: Record<string, unknown> = {};
    const uniqKeys = Array.from(new Set([...Object.keys(objValue), ...Object.keys(srcValue)]));
    for (const key of uniqKeys) {
      const nestedObjsBelow = areNestedObjsBelow(uniqKeys, objValue, srcValue);
      const valueFromSrc = srcValue[key];

      if (valueFromSrc === undefined && nestedObjsBelow) {
        // if there are objects downstream, means the update that obj, keeping these values
        result[key] = objValue[key];
      } else if (valueFromSrc === null) {
        // we delete objects by nullifying them #deletingConfigEntries
        result[key] = null;
      } else {
        // recursively merge nested objects / primitives
        result[key] = mergeConfigsCustomizer(objValue[key], valueFromSrc);
      }
    }
    return result;
  }
  return srcValue;
}
