import React from 'react';
import { Button, Form, FormListFieldData } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { FormField } from '../../../types/FormFields';
import { itemKeyToName, keyForFormList } from './formKeys';
import FormDivider from '../../formElements/FormDivider';
import renderFormItem from './renderFormItem';
import { isPasswordField } from 'shared/hashedOrEncryptedFields';
import Passwords from '../Passwords';

function mapChildSchema(item: FormField, formListItemName: number): React.ReactNode {
  const fieldsMappings = item.childSchema;
  if (!fieldsMappings) return [];
  return fieldsMappings.map((fieldData: FormField) => {
    // we need to re-create the Key to match the dynamic structure with item index
    const updatedFieldData = {
      ...fieldData,
      key: keyForFormList(fieldData.key, formListItemName)
    };
    if (isPasswordField(updatedFieldData.key)) {
      return <Passwords item={updatedFieldData} key={`form_${updatedFieldData.key}`} />;
    } else {
      return renderFormItem(updatedFieldData);
    }
  });
}

function itemEntryName(item: FormField) {
  return item.label.endsWith('s') ? item.label.slice(0, -1) : 'Entry';
}

type RemoveCallBack = (itemName: number) => void;

function formListItemDeleteButton(
  fields: FormListFieldData[],
  item: FormField,
  remove: RemoveCallBack,
  formListItemName: number
): React.ReactNode | null {
  if (fields.length <= 1) return null;
  return (
    <Button
      type="dashed"
      danger
      onClick={() => remove(formListItemName)}
      icon={<MinusCircleOutlined />}
      data-testid="configFormListDelete"
    >
      {`Remove ${itemEntryName(item)} #${formListItemName + 1}`}
    </Button>
  );
}

function mapFormListFieldData(
  fields: FormListFieldData[],
  item: FormField,
  remove?: RemoveCallBack
): React.ReactNode {
  return fields.map(
    (
      {
        key, // so far the key == name == index
        name // ..rest is not required so far
      },
      index
    ) => {
      return (
        <Form.Item key={`${key}_${index}_formItemInList`}>
          {mapChildSchema(item, name)}
          {remove && formListItemDeleteButton(fields, item, remove, name)}
        </Form.Item>
      );
    }
  );
}

function formListNewItemButton(item: FormField, addCallBack: () => void): React.ReactNode {
  return (
    <Button
      type="dashed"
      onClick={() => addCallBack()}
      icon={<PlusOutlined />}
      data-testid="configFormListAdd"
    >
      {`Add new ${itemEntryName(item)}`}
    </Button>
  );
}

function renderFormList(item: FormField): React.ReactNode {
  return (
    <Form.List key={`${item.key}_formList`} name={itemKeyToName(item.key)}>
      {(fields, { add, remove }, { errors }) => (
        <>
          <FormDivider
            key={`${item.key}_divider`}
            label={item.label}
            dataTestID="configFormListDivider"
          />
          {mapFormListFieldData(fields, item, remove)}
          <Form.Item>
            {formListNewItemButton(item, add)}
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  );
}

export default renderFormList;
