// fallback img & texts for Branding
const afLogo = `${process.env.PUBLIC_URL}/android-chrome-512x512.png`;
const afFavicon = `${process.env.PUBLIC_URL}/favicon.ico`;
const afTitle = 'Welcome to Authfish';
const afSubTitle = 'Login with your credentials';
const timeTo = new Date().getFullYear() === 2024 ? 'Present' : new Date().getFullYear();
const afFooter = `© 2024-${timeTo} 🐟 Authfish`;

const defaultBranding = {
  favicon_url: afFavicon,
  logo_url: afLogo,
  title: afTitle,
  subtitle: afSubTitle,
  footer: afFooter
};

export default defaultBranding;
