import { AuthfishConfig, AuthStrategies, userSchema, UserSchema } from 'shared/authfishConfig';

export type BrandingProps = Partial<NonNullable<AuthfishConfig['branding']>>;

export interface authProps {
  strategies: AuthStrategies; // enum
  saml_redirect?: string;
}

export type WithAuthLocalUsers = Pick<AuthfishConfig, 'auth'>;

type PasswordHashes = {
  password_hash__0?: string;
  password_hash__1?: string;
};

export type UserSchemaWithPasswords = UserSchema & PasswordHashes;

// can't use this as part of key in PasswordHashes, so will have to set manually
const passwordHashBase = 'password_hash' as const;

// utility type to get the postfix of a "T" string from "Base"
type ExtractPostfix<T extends string, Base extends string> = T extends `${Base}${infer P}`
  ? P
  : never;
export type PasswordHashPostfixes = ExtractPostfix<keyof PasswordHashes, typeof passwordHashBase>; // '__0' | '__1'

export function hasAuthLocalUsers(
  formValues: Record<string, any>
): formValues is WithAuthLocalUsers {
  const users = formValues.auth?.local?.users;
  if (!users || users.length === 0) return false;
  const valid = userSchema.safeParse(users[0]);
  return valid.success;
}
