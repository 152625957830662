import React from 'react';
import { Helmet } from 'react-helmet-async';

import defaultBranding from '../lib/defaultBranding';
import { BrandingProps } from '../types/afConfig';

interface HeaderProps {
  pageTitle: string;
  branding?: BrandingProps | null;
}

const PageHeader = ({ pageTitle, branding }: HeaderProps) => {
  const favicon = branding?.favicon_url || defaultBranding.favicon_url;
  return (
    <Helmet>
      <title>
        {pageTitle} - {branding?.title || defaultBranding.title}
      </title>
      <link rel="icon" href={favicon} />
    </Helmet>
  );
};
export default PageHeader;
