import isObject from 'shared/isObject';

// tested with configEncryptedFromFormValues, purpose: prepare formValues to merge with full config
function removeEmptyEntries(obj: Record<string, any>): Record<string, any> {
  // we need to substitute nested objects with all fields undefined to null
  // changed fallback from undefined to make it work with lodash merge
  return Object.entries(obj).reduce(
    (newObj: Record<string, any>, [key, keyValue]) => {
      // {}, 'auth', {enabled: true, strategies: Array(3)}
      if (isObject(keyValue)) {
        const cleanedObject = removeEmptyEntries(keyValue);
        // we may have a special case when object has only '' values
        const hasKeys =
          Object.keys(cleanedObject).length > 0 &&
          Object.values(cleanedObject).some((val) => val !== '');
        // let's follow: if we want to delete something => we nullify it #deletingConfigEntries
        newObj[key] = hasKeys ? cleanedObject : null;
      } else if (keyValue !== undefined) {
        newObj[key] = keyValue;
      }
      return newObj;
    },
    {} as Record<string, any>
  );
}

export default removeEmptyEntries;
