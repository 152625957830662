import React from 'react';
import { App as AntdApp, Button, Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { load } from 'js-yaml';
import { FormInstance } from 'antd/lib/form';

import { AuthfishConfig, configSchema } from 'shared/authfishConfig';
import showConfigFormMessage from './helpers/showConfigFormMessage';
import showConfigLoadedMsg from './helpers/showConfigLoadedMsg';
import { decryptConfig } from '../../api/hashOrEncryptAPI';

interface ConfigUploaderProps {
  form: FormInstance;
  uploadBtnRef: React.RefObject<HTMLButtonElement>;
  setLoading: (loading: boolean) => void;
  setConfig: (config: AuthfishConfig) => void;
}

const ConfigUploader = ({ form, uploadBtnRef, setLoading, setConfig }: ConfigUploaderProps) => {
  const { message } = AntdApp.useApp();

  const processUpload = async (file: RcFile) => {
    // startImportFunc eventually starts this
    setLoading(true);
    try {
      // the secrets are encrypted when we export to YAML so let's try to decrypt them
      const decryptedObj = await decryptConfig(
        load(await file.text()) as Record<string, any>,
        message
      );
      const config: AuthfishConfig = configSchema.parse(decryptedObj);
      setConfig(config);
      form.setFieldsValue(config);
      showConfigLoadedMsg(message, `file: ${file.name}`);
    } catch (e: unknown) {
      console.error(e);
      const msg = 'Failed to load config file, wrong syntax';
      showConfigFormMessage(message, msg, 'error', 10);
    }
    setLoading(false);
    // we'll post only if the user will click Save, so canceling an actual upload
    return false;
  };

  const uploadProps: UploadProps = {
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
    accept: '.yaml,.yml',
    beforeUpload: processUpload,
    maxCount: 1, // will be replaced if any further uploads
    showUploadList: false
  };

  return (
    <Upload {...uploadProps}>
      <Button ref={uploadBtnRef} style={{ display: 'none' }} />
    </Upload>
  );
};
export default ConfigUploader;
