import { allCapWords, capitalize } from '../lib/formLabels';

export function camelCaseToSnakeCase(str: string): string {
  // for consistency we'll do "camelCase" => "camel_case"
  // unfortunately lodash's implementation will lower all the letters
  const snakeCasePreserveCase = str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1_$2');
  return snakeCasePreserveCase
    .split('_')
    .map((word) => {
      return word === word.toUpperCase() ? word : word.toLowerCase();
    })
    .join('_');
}

function abbrReplace(str: string): string {
  switch (str) {
    case 'cert':
      return 'certificate';
    default:
      return str;
  }
}

function generateFormFieldLabel(fieldKey: string): string {
  const words = camelCaseToSnakeCase(abbrReplace(allCapWords(fieldKey))).split('_');
  const firstWord = words.length > 1 ? capitalize(words[0]) + ' ' : capitalize(words[0]);
  return firstWord + words.slice(1).join(' ');
}

export default generateFormFieldLabel;
