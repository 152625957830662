import React from 'react';
import { Modal } from 'antd';

interface Props {
  open: boolean;
  message: string;
}

export const NotifyAndLogoutModal = ({ open, message }: Props) => {
  const handleLogout = () => {
    if (window.top) {
      window.top.location.href = `/auth/logout`;
    }
  };

  return (
    <Modal
      open={open}
      title={message}
      onOk={handleLogout}
      footer={(_, { OkBtn, CancelBtn }) => <OkBtn />}
      closeIcon={<></>}
    >
      To correctly propagate the activation process, you will now be logged out.
    </Modal>
  );
};
