import React from 'react';
import { Input, InputNumber } from 'antd';

import { FormField } from '../../../types/FormFields';
import URLInput, { ProtocolsTuple } from '../../formElements/URLInput';
import { getPasswordIndexFromKey, itemKeyToID } from './formKeys';
import generateFormFieldLabel from '../../../configSchemas/generateFormFieldLabel';
import { pureCapitalize } from '../../../lib/formLabels';
import PasswordTextArea from '../PasswordTextArea';

function getProtocolsTuple(itemKey: string): ProtocolsTuple {
  // for ldap urls we should use 'ldap://' protocol | 'ldaps://'
  // while for urls in proxy settings & branding it's ok to use 'http://'
  const ldapPattern: RegExp = /ldap/;
  const brandingPattern: RegExp = /branding/;
  const proxyPattern: RegExp = /proxy/;
  // while experimenting with Keycloak locally we may use http://
  const oidcPattern: RegExp = /oidc/;
  const samlPattern: RegExp = /saml/;
  // const urlPattern: RegExp = /url/;
  if (ldapPattern.test(itemKey)) {
    return [['ldap', 'ldaps'], 0];
  } else if (
    proxyPattern.test(itemKey) ||
    brandingPattern.test(itemKey) ||
    oidcPattern.test(itemKey) ||
    samlPattern.test(itemKey)
  ) {
    return [['http', 'https'], 1];
  }
  return [['https'], 0];
}

function shouldUseTextArea(itemKey: string): [boolean, number | undefined] {
  const patterns: { pattern: RegExp; rows?: number }[] = [
    { pattern: /footer/ },
    { pattern: /snippet/ },
    { pattern: /saml.cert/, rows: 5 },
    { pattern: /saml.decryption_pvk/, rows: 5 }
  ];
  const match = patterns.find(({ pattern }) => pattern.test(itemKey));
  const shouldUse = Boolean(match);
  const rowsNumber = match?.rows || 3;
  return [shouldUse, rowsNumber];
}

export function passwordLabel(item: FormField, splitLabel = false): string {
  // with splitLabel we get e.g. "secret" from "session.secret__0"
  return item.label.toLowerCase().startsWith('password')
    ? 'Password'
    : splitLabel
      ? generateFormFieldLabel(item.key.split('.').pop()?.split('__')[0] || item.label)
      : pureCapitalize(generateFormFieldLabel(item.label));
}

function renderInputElement(item: FormField): React.ReactNode {
  switch (item.type) {
    case 'url':
      const inputID = itemKeyToID(item.key);
      return (
        <URLInput
          id={inputID}
          key={item.key}
          protocolsTuple={getProtocolsTuple(item.key)}
        />
      );
    case 'number':
      return <InputNumber key={item.key}  changeOnWheel />;
    case 'password':
      const secondInput = getPasswordIndexFromKey(item.key) === 1;
      const label = item.key.includes('password_hash')
        ? 'password'
        : passwordLabel(item, true).toLowerCase();
      const [toUseTextArea, rowsNumber] = shouldUseTextArea(item.key);
      return toUseTextArea ? (
        <PasswordTextArea
          key={item.key}
          rows={rowsNumber}
          name={label}
        />
      ) : (
        <Input.Password
          key={item.key}
          placeholder={secondInput ? `Confirm ${label}` : `Enter ${label}`}
          name={secondInput ? `confirm_${label}` : label}
          autoComplete="off"
        />
      );
  }
  const [toUseTextArea, rowsNumber] = shouldUseTextArea(item.key);
  return toUseTextArea ? (
    <Input.TextArea key={item.key} rows={rowsNumber} />
  ) : (
    <Input key={item.key}  />
  );
}

export default renderInputElement;
