export const toAllCaps = ['url', 'http', 'https', 'db', 'pvk'] as const;

function isAllCaps(str: string): boolean {
  return str === str.toUpperCase();
}

export function pureCapitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalize(str: string): string {
  // we don't need to capitalize all caps + specific words
  if (isAllCaps(str)) return str;
  const capitalStr = allCapWords(str);
  return capitalStr !== str ? capitalStr : pureCapitalize(str);
}

export function allCapWords(str: string): string {
  toAllCaps.forEach((substring) => {
    // matching the substring case-insens way
    const regex = new RegExp(substring, 'gi');
    str = str.replace(regex, (match) => match.toUpperCase());
  });
  return str;
}
