import React from 'react';
import { Select } from 'antd';

import { FormField } from '../../../types/FormFields';
import TagsInput from '../../formElements/TagsInput';

function selectMode(multiSelect: boolean): 'multiple' | undefined {
  // 'tags' are now separate element TagsInput
  return multiSelect ? 'multiple' : undefined;
}

function renderSelectElement(item: FormField, multiSelect: boolean = false): React.ReactNode {
  const options = item.options?.map((opt) => ({ label: opt, value: opt }));
  const plsAddPH = `Feel free to add${multiSelect ? ' entries!' : '!'}`;

  return item.options ? (
    <Select
      key={item.key}
      options={options}
      mode={selectMode(multiSelect)}
      placeholder={options && options?.length ? undefined : plsAddPH}
      notFoundContent={null}
    />
  ) : (
    <TagsInput label={item.label} />
  );
}

export default renderSelectElement;
