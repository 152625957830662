import { Typography } from 'antd';
import React from 'react';

export const ActivationKeyMethodsDescription = () => {
  const envVariableCode = `
  export AUTHFISH_ACTIVATION_KEY=<your activation key>
  `;

  return (
    <>
      <Typography.Title>Available activation key methods</Typography.Title>

      <Typography.Title level={4}>Via Environmental variable</Typography.Title>
      <Typography.Paragraph>
        This method is useful for Docker deployments. Just set the <b>AUTHFISH_ACTIVATION_KEY</b>
        environment variable to the activation key you obtained.
        <pre>
          <Typography.Text code>{envVariableCode}</Typography.Text>
        </pre>
      </Typography.Paragraph>

      <Typography.Title level={4}>Via ACTIVATION_KEY.txt file</Typography.Title>
      <Typography.Paragraph>
        You can add a small text file with your activation key to this archive before installing it,
        so it will be picked up automatically at the first boot. The text file should be called{' '}
        <b>ACTIVATION_KEY.txt</b>
        <ul>
          <li>
            The text file should be called <b>ACTIVATION_KEY.txt</b>
          </li>
          <li>It should contain your secret activation key string (no spaces, no new lines)</li>
          <li>It should be added to the root directory of the Authfish</li>
        </ul>
      </Typography.Paragraph>

      <Typography.Title level={4}>Interactive activation key management</Typography.Title>
      <Typography.Paragraph>
        Check <b>Via Activation key management</b> section below
      </Typography.Paragraph>
    </>
  );
};
