import React from 'react';
import { MessageInstance } from 'antd/lib/message/interface';

import showConfigFormMessage from './showConfigFormMessage';

function showConfigLoadedMsg(message: MessageInstance, configSource: string) {
  const msg = (
    <span>
      Config loaded from {configSource}, <br />
      <b>remember to Save it to apply the changes</b> if everything is OK
    </span>
  );
  showConfigFormMessage(message, msg, 'success', 9);
}

export default showConfigLoadedMsg;
