import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider, Layout, App as AntdApp } from 'antd';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ConfigProvider
        theme={
          {
            // Check compactAlgorithm algorithm
            // algorithm: theme.compactAlgorithm,
            // or could use token.colorPrimary as base
          }
        }
      >
        <AntdApp>
          <Layout>
            <App />
          </Layout>
        </AntdApp>
      </ConfigProvider>
    </HelmetProvider>
  </React.StrictMode>
);
