import { AuthfishConfig } from 'shared/authfishConfig';
import {
  getConfigResponse,
  saveConfigResponse,
  errorResponse,
  isGetConfigResponse
} from 'shared/responses';
import { CLIENT_API_PATH } from 'shared/routes';
import sendPost from './sendPost';
import UnauthorizedError, { isUnauthorizedError } from './UnauthorizedError';

const endpointURL = `${CLIENT_API_PATH}/config`;
const serverReadyEndpointURL = `${CLIENT_API_PATH}/restart_ok`;

export interface handleConfigResponse {
  success: boolean;
  // we could get success response or object with error
  response: getConfigResponse | saveConfigResponse | errorResponse;
  status: number;
}

function handleError(error: unknown): handleConfigResponse {
  console.warn(error);
  if (error instanceof Error) {
    return {
      success: false,
      response: { error: error.message },
      status: isUnauthorizedError(error) ? 401 : 500
    };
  } else
    return {
      success: false,
      response: { error: `Unknown error ${error}` },
      status: 500
    };
}

async function handleResponse(resp: Response): Promise<handleConfigResponse> {
  let respData;
  try {
    respData = await resp.json();
  } catch (error) {
    respData = { error: `error parsing response: ${error}` };
  }
  if (resp.status === 200) {
    return {
      success: true,
      response: respData,
      status: 200
    };
  } else {
    return {
      success: false,
      response: respData,
      status: resp.status
    };
  }
}

export async function getConfig(createdAtTS?: string): Promise<handleConfigResponse> {
  // tests in ConfigPage.test.tsx
  const getURL = createdAtTS ? `${endpointURL}?created_at=${createdAtTS}` : endpointURL;
  try {
    const response = await fetch(getURL);
    return await handleResponse(response);
  } catch (error: unknown) {
    return handleError(error);
  }
}

export async function awaitServerReady(): Promise<boolean> {
  let msg = 'saveConfig: server is not ready, re-trying...';
  let respStatus = 0;
  try {
    const serverReadyResp = await fetch(serverReadyEndpointURL);
    respStatus = serverReadyResp.status;
    if (respStatus === 200) return true;
  } catch (error: unknown) {
    msg = `saveConfig: ${error}, re-trying...`;
  }
  console.warn(msg);
  if (respStatus === 401) {
    // means smth off; no point re-trying
    throw new UnauthorizedError(respStatus.toString());
  }
  await new Promise((resolve) => setTimeout(resolve, 5000));
  return awaitServerReady();
}

async function tryUpdateTimestamps(addTimestamp: (timestamp: number) => void) {
  let getConfigResp;
  try {
    ({ response: getConfigResp } = await getConfig());
  } catch (error: unknown) {}
  if (getConfigResp && isGetConfigResponse(getConfigResp)) {
    // here we'll get the config before the current, since the latest one
    // won't be in timestampsAvailable => makes no sense to restore the same
    addTimestamp(parseInt(getConfigResp.timestampsAvailable[0]));
  }
}

export async function saveConfig(
  config: AuthfishConfig,
  addTimestamp: (timestamp: number) => void,
  libraryMode: boolean = true
): Promise<handleConfigResponse> {
  // tests in ConfigPage.test.tsx
  try {
    await awaitServerReady(); // in case there was a page reload or smth
    const response = await sendPost(endpointURL, config);
    // turned out this ↘️️ causes problems when used with Next in dev mode
    if (!libraryMode) await awaitServerReady();
    else await new Promise((resolve) => setTimeout(resolve, 5000));
    await tryUpdateTimestamps(addTimestamp);
    return await handleResponse(response);
  } catch (error: unknown) {
    return handleError(error);
  }
}
