import React from 'react';
import { Form } from 'antd';
import { Rule } from 'antd/lib/form';

import { AuthfishConfig } from 'shared/authfishConfig';
import { FormField } from '../../../types/FormFields';
import { itemKeyToName } from './formKeys';
import { getItemLabelFull } from './formTexts';
import renderFormElement from './renderFormElement';
import validationRulesFormField from '../../formElements/utilities/validationRulesFormField';
import isItemRequired from './isItemRequired';
import formLayout from './formLayout';
import formItemInstructions from './formItemInstructions';

function renderFormItem(
  item: FormField,
  confirmPasswordValidator?: Rule,
  dependencies?: string[][],
  config?: AuthfishConfig
): React.ReactNode {
  const { key, type, label } = item;
  return (
    <Form.Item
      key={`${key}_formItem`}
      name={itemKeyToName(key)}
      valuePropName={type === 'checkbox' ? 'checked' : 'value'}
      required={isItemRequired(item)}
      label={getItemLabelFull(type, key, label)}
      rules={validationRulesFormField(item, confirmPasswordValidator)}
      dependencies={dependencies}
      // hasFeedback={type === 'password'}
      data-testid="configFormItem"
      // data-testkey={key} // if tests fail, uncomment this!
      tooltip={formItemInstructions(item, config)}
      {...formLayout}
    >
      {renderFormElement(item)}
    </Form.Item>
  );
}

export default renderFormItem;
