import React from 'react';
import { Checkbox } from 'antd';

import { FormField } from '../../../types/FormFields';
import renderSelectElement from './renderSelectElement';
import renderInputElement from './renderInputElement';

function renderFormElement(item: FormField): React.ReactNode {
  if (!item.type) return; // could be undefined
  switch (item.type) {
    case 'text':
    case 'number':
    case 'url':
    case 'password':
      return renderInputElement(item);
    case 'select':
    case 'multiSelect':
      return renderSelectElement(item, item.type === 'multiSelect');
    case 'checkbox':
      return <Checkbox key={item.key} />;
    default: {
      const unreachable: never = item.type;
      throw new Error(`renderFormElement | unknown item.type: ${unreachable}`);
    }
  }
}

export default renderFormElement;
