import React from 'react';
import type { MenuProps } from 'antd';
import { CalendarOutlined, ExportOutlined, ImportOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/interface';

import formatDate from '../../../lib/formatDate';

function generateMenuItem(
  key: string,
  label: string,
  icon?: React.ReactElement,
  enabled = true
): ItemType {
  return {
    key,
    label,
    icon,
    disabled: !enabled
  };
}

export function dropdownItemsSave(): MenuProps['items'] {
  return [generateMenuItem('export', 'Export to file', <ExportOutlined />)];
}

export function dropdownItemsReset(timestampsAvailable: number[] = []): MenuProps['items'] {
  return [
    generateMenuItem('import', 'Import from file', <ImportOutlined />),
    // these dividers can't have labels; but we'll add a text using CSS
    { type: 'divider' },
    // and the prev saved configs by TS, let's show only the last 10
    ...timestampsAvailable.slice(0, 10).map((timestamp) => {
      return generateMenuItem(
        timestamp.toString(),
        formatDate(new Date(timestamp)),
        <CalendarOutlined />
      );
    })
  ];
}
