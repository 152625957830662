import React from 'react';
import styles from './LoginForm.module.scss';
import { Radio, RadioChangeEvent } from 'antd';
import { authStrategies, AuthStrategies } from 'shared/authfishConfig';

interface AuthTypeSelectorProps {
  authTypesEnabled: AuthStrategies[];
  currentAuthType: AuthStrategies;
  onChange: (e: RadioChangeEvent) => void;
}

export function authTypeCaption(authType: AuthStrategies, short: boolean = false) {
  return authType === 'local'
    ? short
      ? `${authType} auth`
      : `${authType} authorization`
    : authType.toUpperCase();
}

const authTypesHelpTxt = (type: AuthStrategies, authTypesEnabled: AuthStrategies[]) => {
  switch (type) {
    case 'saml':
    case 'oidc':
      return authTypesEnabled.includes(type)
        ? `redirect to ${type.toUpperCase()} gateway`
        : undefined;
    default:
      return undefined;
  }
};

const AuthTypesSelector = ({
  authTypesEnabled,
  currentAuthType,
  onChange
}: AuthTypeSelectorProps) => {
  const authTypeRadioButtons = authStrategies.map((type) => {
    const isDisabled = !authTypesEnabled.includes(type);
    const buttonCaption = authTypeCaption(type);
    return (
      <Radio.Button
        key={type}
        value={type}
        id={type}
        disabled={isDisabled}
        title={authTypesHelpTxt(type, authTypesEnabled)}
      >
        {buttonCaption}
      </Radio.Button>
    );
  });

  return (
    <Radio.Group
      value={currentAuthType}
      // size={"large"}
      onChange={onChange}
      className={styles.login_tabs}
    >
      {authTypeRadioButtons}
    </Radio.Group>
  );
};
export default AuthTypesSelector;
