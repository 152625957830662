import type { UserSchemaWithPasswords, WithAuthLocalUsers } from 'client/src/types/afConfig';
import _ from 'lodash';

function getLocalUsers(formValues: WithAuthLocalUsers): UserSchemaWithPasswords[] {
  const userEntries = formValues.auth.local?.users || [];
  // when we just add a new entry, it could be undefined initially
  return _.cloneDeep(userEntries).filter((u) => u);
}

export default getLocalUsers;
