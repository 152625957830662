import React from 'react';

import { AuthfishConfig } from 'shared/authfishConfig';
import { FormField } from '../../types/FormFields';
import shouldAddDivider from './helpers/shouldAddDivider';
import renderFormList from './helpers/renderFormList';
import renderFormItem from './helpers/renderFormItem';
import FormDivider from '../formElements/FormDivider';

function mapFormItems(itemsMapping: FormField[], config?: AuthfishConfig) {
  let dividersPrefixesIncluded: string[] = [];

  return itemsMapping.flatMap((item: FormField, index) => {
    const { key, childSchema } = item;

    const { dividerAbove, dividerBelow, dividerLabel, includedPrefixes } = shouldAddDivider(
      itemsMapping,
      item,
      dividersPrefixesIncluded
    );
    dividersPrefixesIncluded = includedPrefixes;
    const dividerEl = <FormDivider key={`${key}_divider_${index}`} label={dividerLabel} />;

    // Warning: Each child in a list should have a unique "key" prop
    // when wrapping return into <></> so will break down the return:
    const formElements = [];
    if (dividerAbove) formElements.push(dividerEl);
    const formEl =
      childSchema && childSchema.length > 1
        ? renderFormList(item) // when we have a few elements => composite Object
        : renderFormItem(item, undefined, undefined, config);
    formElements.push(formEl);
    if (dividerBelow) formElements.push(dividerEl);

    return formElements;
  });
}

interface formItemsProps {
  itemsMapping: FormField[];
  config?: AuthfishConfig;
}

const FormItems = ({ itemsMapping, config }: formItemsProps) => {
  return <>{mapFormItems(itemsMapping, config)}</>;
};

export default FormItems;
