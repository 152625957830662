import { FormInstance } from 'antd';

import { DefaultValueTypes, FormField } from '../../../types/FormFields';
import isObject from 'shared/isObject';
import { getSubKey } from './formKeys';

function getDefaultValue(items: FormField[], keyPath: string): DefaultValueTypes | undefined {
  let results = items.filter((item) => item.key === keyPath);
  if (!results.length) {
    // for nested objects if FormList we need another algorithm
    const key = getSubKey(keyPath, 1, keyPath);
    const prefix = keyPath.replace(`.${key}`, '');
    results = items
      .map((item) => {
        if (item.key === prefix && item.childSchema) {
          for (const child of item.childSchema) {
            if (child.key.endsWith(`.${key}`)) return child;
          }
        }
        return undefined;
      })
      .filter((i) => i) as FormField[];
  }
  return results[0]?.defaultValue;
}

function defaultsForUndefined(
  items: FormField[],
  values: Record<string, any>,
  keyPrefix: string = ''
) {
  // we should set default values (if any) for entries which are undefined | null
  const newValues = {} as Record<string, any>;
  Object.entries(values).forEach(([key, value]) => {
    const keyPath = keyPrefix ? `${keyPrefix}.${key}` : key;
    if (isObject(value)) {
      newValues[key] = defaultsForUndefined(items, value, keyPath);
    } else if (Array.isArray(value) && value.length && isObject(value[0])) {
      // FormList will add a null entry when we add a new item, so
      // to get the proper Defaults we'll insert an empty object
      const valueShape = {} as Record<string, any>;
      Object.keys(value[0]).forEach((k) => (valueShape[k] = undefined));
      newValues[key] = [];
      value.forEach((item) => {
        if (!item) {
          // when adding a new entry within FormList
          newValues[key].push(valueShape);
        } else {
          // keyPath += `.${index}`
          newValues[key].push(defaultsForUndefined(items, item, keyPath));
        }
      });
    } else if (value === undefined || value === null) {
      const defaultValue = getDefaultValue(items, keyPath);
      if (defaultValue !== undefined) newValues[key] = defaultValue;
      else newValues[key] = value;
    } else {
      newValues[key] = value;
    }
  });
  return newValues;
}

function preValidateFields(
  items: FormField[],
  form: FormInstance,
  values: Record<string, any>
): void {
  if (!values) return;
  const newValues = defaultsForUndefined(items, values);
  form.setFieldsValue(newValues);
}

export default preValidateFields;
