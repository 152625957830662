import { MessageInstance } from 'antd/lib/message/interface';

export const redirectMsg = 'Success! Redirecting...';

function afLoginRedirect(redirectURL: string, messageInstance?: MessageInstance): void {
  if (messageInstance) messageInstance.success(redirectMsg).then();
  // we'll handle the cookies on the server
  window.location.href = redirectURL;
}

export default afLoginRedirect;
