import { Rule } from 'antd/lib/form';
import { RuleType } from 'rc-field-form/lib/interface';

import validationRules from './validationRules';
import { FormField } from '../../../types/FormFields';
import isItemRequired from '../../config/helpers/isItemRequired';
import isPasswordConfirmItem from '../../config/helpers/isPasswordConfirmItem';

function itemTypeRule(item: FormField): Rule {
  if (!item.type) return {};
  // https://github.com/yiminghe/async-validator#type
  let iType: RuleType | 'array' = 'string';
  switch (item.type) {
    case 'text':
    case 'select':
      iType = 'string';
      break;
    case 'number':
    case 'url':
      iType = item.type;
      break;
    case 'multiSelect':
      iType = 'array';
      break;
    case 'checkbox':
      iType = 'boolean';
      break;
    case 'password':
      return {};
    default:
      const unreachable: never = item.type;
      throw new Error(`itemTypeRule | unknown item.type: ${unreachable}`);
  }
  return { type: iType };
}

function validationRulesFormField(item: FormField, confirmPasswordValidator?: Rule): Rule[] {
  const rules: Rule[] = [];
  rules.push(...validationRules(item.label, item.type, isItemRequired(item)));
  rules.push(itemTypeRule(item));
  // could also use enums & RegEx patterns
  if (isPasswordConfirmItem(item) && confirmPasswordValidator) {
    rules.push(confirmPasswordValidator);
  }
  return rules;
}

export default validationRulesFormField;
