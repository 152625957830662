import React from 'react';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => any,
  isDisabled?: boolean
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick: onClick,
    disabled: isDisabled || false
  } as MenuItem;
}

export interface labelMapping<T = string> {
  category: T;
  icon?: React.ReactElement;
  children?: labelMapping<T>[];
  isDisabled?: boolean;
}

export type OnItemClickHandler<T = string> = (itemLabel: T) => any;

const getMenuItems = <T extends string>(
  itemsToMap: labelMapping<T>[],
  keyPrefix: string = 'menu_item_',
  onItemClickHandler?: OnItemClickHandler<T>
): MenuItem[] => {
  // getItem('Option 1', '1', <PieChartOutlined />),
  return itemsToMap.map(({ category: label, icon, children, isDisabled }, index) => {
    // we need to handle clicks only if the item has no children
    const onClick =
      !children && onItemClickHandler ? () => onItemClickHandler(label) : undefined;
    return getItem(
      label,
      `${keyPrefix}${index + 1}`,
      icon,
      children && getMenuItems(children, `${keyPrefix}_sub_${index + 1}`, onItemClickHandler),
      onClick,
      isDisabled
    );
  });
};

export default getMenuItems;
