import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const buttonStyle: React.CSSProperties = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  background: 'white'
};

type BaseTAProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;
interface PasswordTAProps extends Omit<BaseTAProps, 'onChange' | 'onResize'> {
  value?: string; // will be passed from the Form.Item
  onChange?: (value: string | undefined) => void; // ...also from the Form.Item
}

const PasswordTextArea = ({ value, onChange, ...rest }: PasswordTAProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [textValue, setTextValue] = useState(value || '');

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
    onChange?.(e.target.value);
  };

  return (
    <>
      <Input.TextArea
        value={isPasswordVisible ? textValue : '•'.repeat(textValue?.length)}
        onChange={handleTextChange}
        {...rest}
      />

      <Button
        style={buttonStyle}
        size={'small'}
        type={'text'}
        onClick={togglePasswordVisibility}
        icon={
          isPasswordVisible ? (
            <EyeTwoTone />
          ) : (
            <EyeInvisibleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
          )
        }
      />
    </>
  );
};

export default PasswordTextArea;
