import { FormField } from '../../../types/FormFields';

const defaultFormName = 'config' as const;

export function itemKeyToName(key: string): string[] {
  // NamePath: string | number | (string | number)[]
  // default way that works for everything but arrays of objs, etc:
  return key.split('.');
}

export function itemKeyToID(key: string, formName: string = defaultFormName): string {
  // we need to pass an ID for the Form Item Label: "Form.name_NamePath"
  return `${formName}_${key.replace(/\./g, '_')}`;
}

export function getSubKey(key: string, reverseIndex: number = 1, fallback: string = '') {
  // e.g. auth.local.users => users
  const keySplit = itemKeyToName(key);
  return keySplit.length > reverseIndex - 1 ? keySplit[keySplit.length - reverseIndex] : fallback;
}

export function keyForFormList(key: string, name: number): string {
  // we need to re-create the key once to match the nested structure
  if (!key.startsWith(name.toString())) {
    const lastSub = getSubKey(key, 1, key);
    return `${name}.${lastSub}`;
  }
  return key;
}

export function getPasswordIndexFromKey(key: string): number {
  return parseInt(key[key.length - 1]);
}

export function namePathForUserPassword(userIndex: number): string[] {
  // this is the dependency for the confirm password
  return ['auth', 'local', 'users', userIndex.toString(), 'password_hash__0'];
}

export function namePathForSecret(item: FormField): string[] {
  // this is the dependency for the confirm secret
  const namePath: string[] = item.key.split('.');
  const lastKey = namePath.pop();
  return [...namePath, `${lastKey}__0`];
}
