import { authStrategies, AuthStrategies } from 'shared/authfishConfig';
import { pureCapitalize } from '../lib/formLabels';

export type LoginFormFieldType = {
  authType?: AuthStrategies;
  username?: string;
  password?: string;
};

export const inputTypes = ['text', 'number', 'url'] as const;

type InputTypes = (typeof inputTypes)[number];
type SelectTypes = 'select' | 'multiSelect';
export type FormFieldType = InputTypes | 'checkbox' | SelectTypes | 'password';

export type DefaultValueTypes = string | number | boolean | null | string[] | Record<string, any>;

// object we'll use to generate the Form Items
export type FormField = {
  key: string;
  type: FormFieldType | undefined; // for parentSchema could be the latter
  required: boolean;
  label: string;
  isVisible?: boolean;
  defaultValue?: DefaultValueTypes; // for inputs/selects & checkboxes
  options?: string[]; // for select-type fields
  parentSchema?: FormField; // for elements that are originally nested
  childSchema?: FormField[]; // for arrays, the element schema
};

export const getAuthTypesLabels = () =>
  authStrategies.map((strategy) =>
    strategy === 'local' ? pureCapitalize(strategy) : strategy.toUpperCase()
  );

export const authTypesLabels = getAuthTypesLabels();

export const fieldCategories = [
  'General',
  'Server',
  'Auth',
  'Activation key',
  ...authTypesLabels,
  'Roles'
] as const;

export type AuthTypesLabels = (typeof authTypesLabels)[number];
export type FieldCategories = (typeof fieldCategories)[number];
