import { z } from 'zod';
import type { ActivationKeyRetrievalMode } from './activationKey';

const ActivationKeyRetrievalModeArray = ['Api', 'Env', 'File', 'All', 'None'] as const;

const LogLevelTypeArray = ['warn', 'info', 'error', 'debug', 'trace'] as const;

export const defaultHttpsKey = 'certs/key.pem';
export const defaultHttpsCert = 'certs/cert.pem';
export const defaultActivationKeyRetrievalModes: ActivationKeyRetrievalMode[] = ['All'];
export const defaultActivationKeyFilePath = 'ACTIVATION_KEY.txt';
export const defaultActivationKeyRefreshInterval = '1h';
export const defaultActivationKey =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJFUzUxMiJ9.eyJleHAiOjg4MDYxMjEyODAwLCJpc3MiOiJodHRwczovL2FwaS5iZXNodS50ZWNoIiwiaWF0IjoxNjYxMzU2MTAxLCJqdGkiOiJyb3JfbGljXzI1YjJhYWE4LTE0MDEtNGI4Zi04ZDBmLTZjMzE3ZjliYTY3MCIsImF1ZCI6InJlYWRvbmx5cmVzdF9rYm4iLCJzdWIiOiIxMTExMTExMS0xMTExLTExMTEtMTExMS0xMTExMTExMSIsImxpY2Vuc29yIjp7Im5hbWUiOiJCZXNodSBMaW1pdGVkIHQvYSBSZWFkb25seVJFU1QgU2VjdXJpdHkiLCJjb250YWN0IjpbInN1cHBvcnRAcmVhZG9ubHlyZXN0LmNvbSIsImZpbmFuY2VAcmVhZG9ubHlyZXN0LmNvbSJdLCJpc3N1ZXIiOiJzdXBwb3J0QHJlYWRvbmx5cmVzdC5jb20ifSwibGljZW5zZWUiOnsibmFtZSI6IkFub255bW91cyBGcmVlIFVzZXIiLCJidXlpbmdfZm9yIjpudWxsLCJiaWxsaW5nX2VtYWlsIjoidW5rbm93bkByb3JmcmVlLmNvbSIsImFsdF9lbWFpbHMiOltdLCJhZGRyZXNzIjpbIlVua25vd24iXX0sImxpY2Vuc2UiOnsiY2x1c3Rlcl91dWlkIjoiKiIsImVkaXRpb24iOiJrYm5fZnJlZSIsImVkaXRpb25fbmFtZSI6IkZyZWUiLCJpc1RyaWFsIjpmYWxzZX19.AUpJKXec7Ed7z6v9SsK3ingQIN8WGZDEMXC5cDn2cLeWsPopwtcfXncptYUXfFUV6diJG-pFpVC41xKHBrZetVIlAcH5OJCEWIlxzMho-WrwDn8rjpTcVDE8tW_JCoE0uteTOLXy97V8vDdyW5pmJQjb7pUd2zvECxGjwFxVsrdsBDkg';
export const defaultTokenPublicKey =
  'LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlHYk1CQUdCeXFHU000OUFnRUdCU3VCQkFBakE0R0dBQVFCZW9UZTF1UDhCaFRSdFdDR2hNTThJWUFwSElKRgppTWJXcEJFa0M2ZHJoMlpXSzVkSTFxL25ENzltU1laVjBzWlFjTTROclVZQ0J3YkpDWlZLTHlIWlh0Y0E4Vld0CmlqN05NWlkrUEJDemtvQVJDbUlKZHprQkI5a1RxRFB6U29aZkRqQW54ZzF5OGxoUVR1dFJ2Z09jTUhKRDgrd3kKV3hLYlU2L1JCVFU2d2pJZE5pND0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t';
export const defaultTrialUrl = 'https://readonlyrest.com/customer/trial?cluster_uuid=*';
export const defaultActiveSubscriberActivationKeyUrl =
  'https://readonlyrest.com/customer/activation_keys?cluster_uuid=*';

export const httpsSchema = z
  .object({
    enabled: z.boolean().default(false),
    key: z.string().optional().default(defaultHttpsKey),
    cert: z.string().optional().default(defaultHttpsCert)
  })
  .refine((data) => !data.enabled || (data.key && data.cert), {
    message: 'Key and cert are required when HTTPS is enabled'
  });

const sessionSchema = z.object({
  cookie_name: z.string(),
  secret: z.string(),
  max_age_hours: z.number().int().min(1).default(60),
  persistent_storage: z.boolean().default(true),
  db_path: z.string().optional().nullable().default('sessions.db')
});

const proxySchema = z.object({
  enabled: z.boolean(),
  target: z.string().url()
});

export const authStrategies = ['local', 'saml', 'ldap', 'oidc'] as const;

export type AuthStrategies = (typeof authStrategies)[number];

// user can't edit config, admin can't edit ProtectedSettings
export const roles = ['user', 'admin', 'superuser'] as const;

export const userSchema = z.object({
  username: z.string(),
  password_hash: z.string(),
  algorithm: z.enum(['sha256', 'sha512']).default('sha512'),
  roles: z.array(z.enum(roles)).default(['user'])
});

export type UserSchema = z.infer<typeof userSchema>;

const localStrategySchema = z.object({
  users: z.array(userSchema)
});

const ldapStrategySchema = z.object({
  url: z.string().url(),
  bindDN: z.string(),
  bindCredentials: z.string(),
  searchBase: z.string(),
  searchFilter: z.string(),
  usernameField: z.string(),
  passwordField: z.string()
});

export const defaultRoleParam = 'Role';

const samlStrategySchema = z.object({
  entry_point: z.string().url(),
  // protocol: z.string().default('https'), // deprecated, we could infer from entrypoint URL
  issuer: z.string(),
  cert: z.string(),
  logout_callback_url: z.string().url(),
  decryption_pvk: z.string(),
  accepted_clock_skew_ms: z.number().int().min(-1).default(-1),
  usernameParameter: z.string().default('nameID'),
  groupsParameter: z.string().default(defaultRoleParam)
});

export const defaultOIDCScopes = ['openid', 'profile', 'email'];

const keyCloakPathPrefix = '/protocol/openid-connect';

export const defaultLogoutPath = `${keyCloakPathPrefix}/logout`; // default logout path
export const defaultUsernameParam = 'preferred_username'; // depends on library used
export const defaultGroupsParam = 'groups';

const oidcStrategySchema = z.object({
  issuer: z.string().url(),
  callbackURL: z.string().url().optional().nullable().default(null).or(z.undefined()),
  logoutPath: z.string().optional().default(defaultLogoutPath),
  clientID: z.string(),
  clientSecret: z.string(),
  // some platforms use non-standard scopes like
  // https://learn.microsoft.com/en-us/entra/identity-platform/scopes-oidc#openid-connect-scopes
  scope: z.array(z.string()).optional().default(defaultOIDCScopes),
  usernameParameter: z.string().optional().default(defaultUsernameParam), // preferred_username in ROR
  groupsParameter: z.string().optional().default(defaultGroupsParam),
  proxyURL: z.string().url().optional().nullable().default(null).or(z.undefined())
});

export type OIDCStrategySchema = z.infer<typeof oidcStrategySchema>;

export const authSchema = z.object({
  enabled: z.boolean(),
  strategies: z.array(z.enum(authStrategies)).default(['local']),
  local: localStrategySchema.optional(),
  ldap: ldapStrategySchema.optional(),
  saml: samlStrategySchema.optional(),
  oidc: oidcStrategySchema.optional()
});

// export type AuthSchema = z.infer<typeof authSchema>;

export const brandingSchema = z.object({
  // logo_url, title, subtitle, footer, custom_head_snippet
  logo_url: z.string().url().optional(), // vs the image stored separately if uploaded directly
  favicon_url: z.string().url().optional(),
  title: z.string(),
  subtitle: z.string(),
  footer: z.string().optional(),
  custom_head_snippet: z.string().optional() // can include stylesheets, <script> etc.
});

export type RouteMatcher = string | RegExp;

const restrictedPaths = ['/auth/', '/login/']; // import them ..?
const restrictedPathMsg = 'The following paths are restricted: ' + restrictedPaths.join(', ');
const isStringNotRestricted = (route: string): boolean => {
  return !restrictedPaths.includes(route);
};

const routeMatcherSchema = z.union([
  z.string().refine(isStringNotRestricted, { message: restrictedPathMsg }),
  z.instanceof(RegExp)
]);

const PublicRoutesSchema = z
  .array(routeMatcherSchema)
  .default([])
  .refine(
    (routes) =>
      !routes.some((route) => typeof route === 'string' && restrictedPaths.includes(route)),
    { message: restrictedPathMsg }
  );

const dateUnitSchema = z.string().regex(/[smhd]$/, {
  message: "The string must end with one of the units: 's', 'm', 'h', 'd'."
});

const activationKeySchema = z.object({
  refresh_interval: dateUnitSchema.default(defaultActivationKeyRefreshInterval),
  retrieval_modes: z
    .array(z.enum(ActivationKeyRetrievalModeArray))
    .default(defaultActivationKeyRetrievalModes),
  default_activation_key: z.string().default(defaultActivationKey),
  token_public_key: z.string().default(defaultTokenPublicKey),
  trial_url: z.string().default(defaultTrialUrl),
  active_subscriber_activation_key_url: z.string().default(defaultActiveSubscriberActivationKeyUrl)
});

export const configSchema = z.object({
  host: z.string().regex(/^[a-zA-Z0-9.-]+$/),
  port: z.number().int().min(1).max(65535).default(5601),
  https: httpsSchema,
  session: sessionSchema,
  proxy: proxySchema,
  activation_key: activationKeySchema.default({}),
  auth: authSchema,
  // new entries to reflect "BlobObject" migration
  public_routes: PublicRoutesSchema,
  branding: brandingSchema.optional().nullable().default(null),
  log_level: z.enum(LogLevelTypeArray).default('info')
});

export type AuthfishConfig = z.infer<typeof configSchema>;
