import { z } from 'zod';

import type { ProtectedSettings } from 'shared/AuthfishParams';
import { FormField, FormFieldType } from '../types/FormFields';
import { inferFieldType, isZodArray, isZodObject, getUnderlyingSchema } from './inferFieldType';
import { inferDefaultValue, inferOptions, inferShape } from './inferShape';
import generateFormFieldLabel from './generateFormFieldLabel';
import hashedOrEncryptedFields from 'shared/hashedOrEncryptedFields';

function isVisible(protectedSettings: ProtectedSettings[] = [], key: string = ''): boolean {
  // if the key or any of its parent settings is protected
  let keyParts: string[] = key.split('.');
  let currentKey: string = '';
  for (let part of keyParts) {
    currentKey = currentKey ? `${currentKey}.${part}` : part;
    if (protectedSettings.includes(currentKey as ProtectedSettings)) {
      return false;
    }
  }
  return true;
}

function getSpecificFieldTypes(key: string): FormFieldType | undefined {
  // here we set specific types for specific fields
  if (hashedOrEncryptedFields(key)) return 'password';
  return undefined;
}

function processPrimitiveField(
  fieldSchema: z.ZodTypeAny,
  protectedSettings: ProtectedSettings[] = [],
  key: string = '',
  parentSchema?: FormField,
  childSchema?: FormField[],
  typeOverride?: FormFieldType
): FormField {
  const isOptional = fieldSchema.isOptional();
  if (!typeOverride) typeOverride = getSpecificFieldTypes(key);
  const fieldType = typeOverride || inferFieldType(fieldSchema);
  const keyForLabel = key.split('.').pop();
  return {
    key,
    type: fieldType,
    required: !isOptional,
    isVisible: isVisible(protectedSettings, key),
    label: generateFormFieldLabel(keyForLabel || key),
    defaultValue: inferDefaultValue(fieldSchema),
    options: inferOptions(fieldSchema),
    parentSchema,
    childSchema
  };
}

/**
 * Generates Form Fields mapping based on the given schema.
 *
 * @param {z.ZodTypeAny} schema - The schema used to generate the form fields.
 * @param protectedSettings - The array of Setting keys we'll need to hide | make readOnly
 * @param {string} keyPrefix - The prefix for nested structures with recursion.
 * Defaults to an empty string.
 * @param {FormField[]} parentSchema - The parent schema for nested structures.
 * @param {FormField} childSchema - The child (element) schema for arrays.
 * @return {FormField[]} The generated form fields.
 */
function generateFormFields(
  schema: z.ZodObject<any>,
  protectedSettings: ProtectedSettings[] = [],
  keyPrefix: string = '', // for nested structures w/recursion
  parentSchema?: FormField,
  childSchema?: FormField[]
): FormField[] {
  function resetNestedStructs() {
    // we should not carry over parent/child schemas to the other structures
    parentSchema = undefined;
    childSchema = undefined;
  }

  const shape = inferShape(schema);
  const fields: FormField[] = [];
  for (const innerKey in shape) {
    const key = keyPrefix ? `${keyPrefix}.${innerKey}` : innerKey;
    const fieldSchema = shape[innerKey];
    let fieldsMapping: FormField[];
    const underlyingSchema = getUnderlyingSchema(fieldSchema);
    if (isZodArray(underlyingSchema)) {
      // this part is hacky now... refactor later! also inferFieldType seems can't have ZodArray ...atm?
      const arrElement = underlyingSchema.element;
      const elementSchema = getUnderlyingSchema(arrElement);
      // since we don't support multidimensional arrays, we'll override childSchema here
      childSchema =
        isZodObject(elementSchema) || inferShape(elementSchema) // unions w Obj
          ? generateFormFields(arrElement, protectedSettings, key)
          : [processPrimitiveField(arrElement, protectedSettings, key)];
      // we also need to figure out isVisible can't just inherit since we reset nested structures
      childSchema = childSchema.map((i) => ({
        ...i,
        isVisible: isVisible(protectedSettings, i.key)
      }));
      const typeOverride = getSpecificFieldTypes(key) || 'multiSelect';
      // so far it seems easiest to override the type here since for Objects it's undefined
      // while here we definitely know that we are inside of array
      // 📝 we use fieldSchema here since it's Array + Effects, Defaults, etc
      fieldsMapping = [
        processPrimitiveField(
          fieldSchema,
          protectedSettings,
          key,
          parentSchema,
          childSchema,
          typeOverride
        )
      ];
      resetNestedStructs();
    } else if (isZodObject(underlyingSchema)) {
      // we need to save the parent schema since it could have defaults | optional, etc
      parentSchema = processPrimitiveField(fieldSchema, protectedSettings, key); // re-create every time!
      // recursion for the nested structures, flatten the results w keys like 'auth.local.key' etc
      fieldsMapping = generateFormFields(
        underlyingSchema,
        protectedSettings,
        key,
        parentSchema,
        childSchema
      );
      resetNestedStructs();
    } else {
      fieldsMapping = [
        processPrimitiveField(fieldSchema, protectedSettings, key, parentSchema, childSchema)
      ];
    }
    fields.push(...fieldsMapping);
  }
  return fields.filter((field) => field.isVisible);
}

export default generateFormFields;
