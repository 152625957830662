import { authTypesLabels, FormField } from '../types/FormFields';
import { fieldCategories, FieldCategories } from '../types/FormFields';
import { configSchema } from 'shared/authfishConfig';
import generateFormFields from './generateFormFields';

export function getAllKeysFromConfig(maxLevel: number = 2): string[] {
  // level 1 means the top-level key
  const allKeysUsed = generateFormFields(configSchema)
    .map((field) => {
      return field.key.split('.').length <= maxLevel ? field.key : undefined;
    })
    .filter((key) => key !== undefined);
  return Array.from(new Set(allKeysUsed)) as string[];
}

function getKeysForDefault(): string[] {
  const allKeys = getAllKeysFromConfig();
  const keysTaken = fieldCategories.flatMap((cat: FieldCategories) => {
    return keyShouldStartFromByCat(cat, false);
  });
  return allKeys.filter((key) => {
    return !keysTaken.some((k) => key.startsWith(k));
  });
}

export function keyShouldStartFromByCat(
  activeLabel: FieldCategories,
  returnDefault: boolean = true
): string[] {
  // we'll return an array of appropriate keys for the FormField
  switch (activeLabel) {
    case 'Server':
      return ['host', 'port', 'https', 'session', 'proxy'];
    case 'Auth':
      return authTypesLabels.flatMap((l) => keyShouldStartFromByCat(l, false));
    case 'Local':
    case 'LDAP':
    case 'SAML':
    case 'OIDC':
      return [`auth.${activeLabel.toLowerCase()}`];
    case 'Activation key': {
      return ['activation_key'];
    }
    case 'Roles': // _TODO: NEED 2 ADD THESE TO CONFIG && REPLACE ENUM IN AUTH | TMP DISABLED
      return ['roles']; // ...?
    default: // General
      return returnDefault ? getKeysForDefault() : [];
  }
}

function filterFormFields(formFields: FormField[], activeLabel?: FieldCategories): FormField[] {
  if (!activeLabel) return formFields;
  const keysStart = keyShouldStartFromByCat(activeLabel);
  return formFields.filter((field) => keysStart.some((prefix) => field.key.startsWith(prefix)));
}

export default filterFormFields;
