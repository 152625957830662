import { Rule } from 'antd/lib/form';
import { namePathForSecret, namePathForUserPassword } from './formKeys';
import { FormField } from '../../../types/FormFields';

function confirmPasswordValidator(item: FormField, userIndex: number): Rule {
  return ({ getFieldValue }: any) => {
    const controlValue: string = getFieldValue(
      !isNaN(userIndex) ? namePathForUserPassword(userIndex) : namePathForSecret(item)
    );
    return {
      validator: (_rule: any, value: string): Promise<void> => {
        // console.log(`validating ${value} -vs- ${controlValue}`);
        if (value === controlValue || (value === '' && controlValue === undefined)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Passwords do not match!'));
      }
    };
  };
}

export default confirmPasswordValidator;
