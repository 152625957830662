class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

export function isUnauthorizedError(error: unknown): error is UnauthorizedError {
  return (
    error instanceof UnauthorizedError ||
    (error instanceof Error && error.name === 'UnauthorizedError') ||
    (error !== null &&
      error !== undefined &&
      error.toString &&
      error.toString().includes('UnauthorizedError'))
  );
}
export default UnauthorizedError;
