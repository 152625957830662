import { MessageInstance } from 'antd/lib/message/interface';
import _ from 'lodash';
import { PasswordHashPostfixes } from '../types/afConfig';
import isObject from 'shared/isObject';
import hashedOrEncryptedFields, { isPasswordField } from 'shared/hashedOrEncryptedFields';
import { hashPasswordAPI, encryptSecretAPI } from './hashOrEncryptAPI';

// we can't get values from type, but this will serve as a guardrail if the naming changes
const [hashPostFix1, hashPostFix2]: PasswordHashPostfixes[] = ['__0', '__1'];

// determine if the key is "password_hash" or whatever the field is called
const isTargetKey = (key: string) => !(key.includes(hashPostFix1) || key.includes(hashPostFix2));
const removePostFixes = (key: string) => key.replace(hashPostFix1, '').replace(hashPostFix2, '');

async function processHashedEntries(
  key: string,
  obj: Record<string, any>,
  message?: MessageInstance
): Promise<Record<string, any>> {
  // hashing the passwords: encode e.g. password_hash__0 & ...__1 (raw text) => password_hash
  // we may need to hash the fields named differently e.g. smth__0 & __1 ==> smth
  let objCopy = _.cloneDeep(obj);

  // we don't know what's the current key, while we need to figure out naming for these:
  const targetKey = removePostFixes(key); // e.g. "password_hash"
  const hashKey = isTargetKey(key) // e.g. "password_hash__0"
    ? `${key}${hashPostFix1}`
    : key.replace(hashPostFix2, hashPostFix1);

  // adding the hashed password & removing the 'raw' passwords
  if (objCopy[hashKey]) {
    objCopy[targetKey] = await hashPasswordAPI(hashKey, objCopy, obj.algorithm, message);
  }
  delete objCopy[hashKey];
  delete objCopy[hashKey.replace(hashPostFix1, hashPostFix2)];
  return objCopy;
}

async function processSecretEntries(
  key: string,
  obj: Record<string, any>,
  message?: MessageInstance
): Promise<Record<string, any>> {
  // encrypting the secrets
  let objCopy = _.cloneDeep(obj);
  objCopy[key] = await encryptSecretAPI(key, obj, message);
  return objCopy;
}

/**
 * Hashes or encrypts the form values recursively based on the type of data:
 * - passwords should be hashed with `processHashedEntries`
 * - while the secrets are encrypted using `processSecretEntries`
 */
async function hashOrEncrypt(
  formValues: Record<string, any>,
  message?: MessageInstance
): Promise<Record<string, any>> {
  let formValuesCopy = _.cloneDeep(formValues);
  for (const [key, value] of Object.entries(formValuesCopy)) {
    if (isObject(value)) {
      formValuesCopy[key] = await hashOrEncrypt(value, message);
    } else if (Array.isArray(value) && value.every(isObject)) {
      formValuesCopy[key] = await Promise.all(value.map((v) => hashOrEncrypt(v, message)));
    } else if (hashedOrEncryptedFields(key)) {
      // we hash the passwords, while encrypting the secrets
      formValuesCopy = isPasswordField(key)
        ? await processHashedEntries(key, formValuesCopy, message)
        : await processSecretEntries(key, formValuesCopy, message);
    } else {
      formValuesCopy[key] = value;
    }
  }
  return formValuesCopy;
}

export default hashOrEncrypt;
