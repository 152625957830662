import type { AuthfishConfig } from './authfishConfig';
import serverURL from './serverURL';

// routes for React app
export const APP_LOGIN_PATH = '/login/app_login';
export const APP_CONFIG_PATH = '/auth/config/';

// API
export const CLIENT_API_PATH = '/client_api';

// moved from strategies/common since we'll need some of those at client
export const SUCCESS_ROUTE = '/';
export const LOGIN_ROUTE = '/auth/login';
export const LOGOUT_ROUTE = '/auth/logout';

export function fullLoginRoute(config?: AuthfishConfig): string {
  return `${config ? serverURL(config) : '...'}${LOGIN_ROUTE}`;
}

// SAML
export const SAML_LOGIN_ROUTE = '/auth/login-saml';
export const SAML_LOGOUT_ROUTE = '/auth/logout-saml';

export function fullSAMLLogoutURL(config?: AuthfishConfig) {
  return `${config ? serverURL(config) : '...'}${SAML_LOGOUT_ROUTE}`;
}

// OIDC
export const OIDC_LOGIN_ENDPOINT = '/login-oidc';
export const OIDC_CALLBACK_ENDPOINT = `${OIDC_LOGIN_ENDPOINT}/callback`;
export const OIDC_LOGOUT_ENDPOINT = '/logout-oidc';

export function defaultOIDCCallbackURL(config?: AuthfishConfig) {
  return `${config ? serverURL(config) : '...'}/auth${OIDC_CALLBACK_ENDPOINT}`;
}
